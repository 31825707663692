import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { ReactComponent as SvgLogo } from "../../assets/logo/logo.svg";
import { ReactComponent as SvgCircle } from "../../assets/logo/circle.svg";
import { getStoredBasket as getStoredBasketAction } from "../../redux/modules/login";

import Container from "../container/Container";
import UiBtnColor from "../buttons/UiBtnColor";
import UiBtnIcon from "../buttons/UiBtnIcon";
import UiIcon from "../icon/UiIcon";
import UiMenuList from "../menu/UiMenuList";

import "./UiHeader.scss";

const UiHeader = (props) => {
  let navigate = useNavigate();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [basket, setBasket] = useState([]);

  const setMenuOpen = () => {
    let new_v = !dropdownOpen;
    setDropdownOpen(new_v);
    props.onChangeMenu();
  };

  useEffect(() => {
    if (props.login.basketList) {
      setBasket(props.getStoredBasket());
    }
  }, [props.login.basketList]);

  useEffect(() => {
    if (props.login.user) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  }, [props.login.user]);

  return (
    <header id="header" className="header-wrap">
      <div className="header-info-wrap">
        <Container>
          <div className="header-info">
            <a
              href="https://yandex.ru/maps/80/yuzhno-sakhalinsk/chain/flowery/177239590117/?ll=142.740275%2C46.954665&sll=142.740275%2C46.954656&z=13"
              className="header-info-item"
            >
              <UiIcon fill="pink" icon="pin" size={16} />
              <p>Южно-Сахалинск</p>
            </a>
            <div className="header-info-item">
              <UiIcon fill="pink" icon="clock" size={16} />
              <p>Круглосуточная доставка</p>
            </div>
            <div className="header-info-item">
              <UiIcon fill="pink" icon="checked-box" size={16} />
              <p>от 30 до 60 мин</p>
            </div>
            <div className="header-info-social">
              <div className="nav-menu-rating">
                <a
                  href="https://yandex.ru/maps/org/flowery/53422456934/?ll=142.732974%2C46.964290&z=16"
                  className="nav-menu-rating-btn"
                >
                  <UiIcon icon="yandex" size={13} />
                  <span>4.9</span>
                </a>
                <a
                  href="https://www.google.com/search?q=flowery+%D1%8E%D0%B6%D0%BD%D0%BE-%D1%81%D0%B0%D1%85%D0%B0%D0%BB%D0%B8%D0%BD%D1%81%D0%BA&hl=ru&biw=1440&bih=789&tbm=lcl&ei=_fPsY8iOGNa7xc8P__6jsA4&oq=flowery+%D1%8E%D0%B6%D0%BD%D0%BE+%D1%81&gs_lcp=Cg1nd3Mtd2l6LWxvY2FsEAMYADIGCAAQFhAeOgQIABBDOgUIABCABDoLCAAQFhAeEA8Q8QRQhQNYoxVgrhtoAHAAeACAAaEBiAGHCZIBAzAuOJgBAKABAcABAQ&sclient=gws-wiz-local#rlfi=hd:;si:;mv:[[46.9651427,142.7560436],[46.9501243,142.7319847]];tbs:lrf:!1m4!1u3!2m2!3m1!1e1!2m1!1e3!3sIAE,lf:1,lf_ui:4"
                  className="nav-menu-rating-btn"
                >
                  <UiIcon icon="google-g" size={14} />
                  <span>4.5</span>
                </a>
                <a
                  href="https://2gis.ru/yuzhnosakhalinsk/branches/70000001032874507?m=142.733336%2C46.964377%2F16"
                  className="nav-menu-rating-btn"
                >
                  <UiIcon icon="2gis" size={14} />
                  <span>4.4</span>
                </a>
              </div>
              <UiBtnIcon
                color=""
                fill="white"
                icon="whatsapp"
                iconSize={24}
                size={24}
                href="https://api.whatsapp.com/send/?phone=%2B79147541334&text&type=phone_number&app_absent=0"
              />
              <UiBtnIcon
                color=""
                fill="white"
                icon="telegram"
                iconSize={24}
                size={24}
                href="https://t.me/Flowerybot"
              />
            </div>
          </div>
        </Container>
      </div>
      <Container>
        <div className="header">
          <div className="header-main">
            <a className="btn header-logo" href="/">
              <SvgLogo className="header-logo-icon" />
            </a>
            <div className="header-about">
              <p>Позвонить нам</p>
              <a className="header-about-phone" href="tel:88003334409">
                +7 (800) 333-44-09
              </a>
            </div>
          </div>
          <nav
            className={
              "nav-menu nav-menu-main" + (dropdownOpen ? " active" : "")
            }
          >
            <SvgCircle className="nav-menu-mobile-cover1" />
            <SvgCircle className="nav-menu-mobile-cover2" />
            <div className="nav-menu-mobile">
              <div className="nav-menu-mobile-wrap">
                <a className="btn header-logo" href="/">
                  <SvgLogo className="header-logo-icon" />
                </a>
                <UiBtnIcon
                  color="blue"
                  fill="white"
                  icon="close"
                  iconSize={12}
                  size={42}
                  onClick={() => setMenuOpen()}
                />
              </div>
            </div>
            <UiMenuList menuList={props.menuList2} />
            <div className="nav-menu-mobile">
              <div className="nav-menu-mobile-wrap">
                <div className="header-about">
                  <p>Позвонить нам</p>
                  <a className="header-about-phone" href="tel:88003334409">
                    +7 (800) 333-44-09
                  </a>
                </div>
                <div className="header-info-social">
                  <UiBtnIcon
                    color=""
                    fill="green"
                    icon="whatsapp"
                    iconSize={36}
                    size={36}
                  />
                  <UiBtnIcon
                    color=""
                    fill="blue"
                    icon="telegram"
                    iconSize={36}
                    size={36}
                  />
                </div>
              </div>
            </div>
          </nav>
          <div className="header-profile">
            <UiBtnIcon
              fill="pink"
              icon="search"
              iconSize={20}
              size={32}
              onClick={() => props.onSearchOpen()}
            />
            <UiBtnIcon
              fill={basket.length > 0 ? "white" : "pink"}
              color={basket.length > 0 ? "pink" : ""}
              icon="bag"
              iconSize={20}
              size={32}
              onClick={() => navigate("/order")}
            />
            <UiBtnIcon
              fill="pink"
              icon="user"
              iconSize={20}
              size={32}
              onClick={() =>
                isLogin ? navigate("/profile") : navigate("/login")
              }
            />
          </div>
          <div className="nav-menu-button">
            <UiBtnIcon
              color=""
              fill="pink"
              icon="menu"
              iconSize={20}
              size={48}
              onClick={() => setMenuOpen()}
            />
          </div>
        </div>
      </Container>
      <div
        className={
          "header-categories" +
          (props.stick ? " stick" : "") +
          (dropdownOpen ? " menu-open" : "")
        }
      >
        <Container>
          <nav className={"nav-menu nav-menu-categories"}>
            <ul className="nav-menu-list">
              <li className="nav-menu-item-catalog">
                <a href={"/catalog"}>
                  <UiIcon fill="white" icon="menu" size={18} />
                  <span>Все букеты</span>
                </a>
              </li>
            </ul>
            <UiMenuList menuList={props.menuList1} />
          </nav>
        </Container>
      </div>
    </header>
  );
};

export default connect(({ login }) => ({ login }), {
  getStoredBasket: getStoredBasketAction,
})(UiHeader);
