import React from "react";
import UiBarNum from "../bars/UiBarNum";
import UiBtnIcon from "../buttons/UiBtnIcon";

import "./UiBasketCard.scss";

const UiBasketCard = (props) => {
  return (
    <div className="basket-card">
      <div className="basket-card-wrap">
        <img className="basket-card-image" src={props.image} />
        <div className="basket-card-info">
          <h3>{props.title}</h3>
          {/*<p>{props.text}</p>*/}
        </div>
      </div>
      <div className="basket-card-control-wrap">
        <div className="basket-card-control">
          {props.onMinus ? <UiBarNum num={props.num} small={true} onMinus={props.onMinus} onPlus={props.onPlus} /> : null}
        </div>
        <div className="basket-card-control">
          <p>
            {props.onMinus ? <strong>{props.totalPrice} ₽</strong> : null}
          </p>
        </div>
        <div className="basket-card-button">
          {props.onClick ?
            <UiBtnIcon
              fill="pink"
              icon="close"
              iconSize={10}
              size="24"
              onClick={props.onClick}
            /> : null}
        </div>
      </div>
    </div>
  );
};
export default UiBasketCard;
