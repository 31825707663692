import React from "react";
import { Routes, Route } from "react-router-dom";

import ProtectedRoute from "./routes/ProtectedRoute";

import HomePage from "../pages/home/HomePage";

import CatalogPage from "../pages/catalog/CatalogPage";
import SearchPage from "../pages/catalog/SearchPage";
import ProductPage from "../pages/product/ProductPage";

import LoginPage from "../pages/login/LoginPage";
import ProfilePage from "../pages/profile/ProfilePage";

import OrderPage from "../pages/order/OrderPage";
import UserOrderPage from "../pages/order/UserOrderPage";
import UserPayPage from "../pages/order/UserPayPage";
import OrderSuccessPage from "../pages/order/OrderSuccessPage";

import DeliveryPage from "../pages/static/DeliveryPage";
import ContactsPage from "../pages/static/ContactsPage";
import AboutPage from "../pages/static/AboutPage";
import CorpPage from "../pages/static/CorpPage";

import NotFoundPage from "../pages/notfound/NotFoundPage";

function AppNavigator() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/quiz" element={<HomePage />} />
      <Route path="/product/:title" element={<HomePage />} />

 
      <Route path="/catalog" element={<CatalogPage />} />
      <Route path="/catalog/:name" element={<CatalogPage />} />
      <Route path="/catalog/:name/:title" element={<CatalogPage />} />

      <Route path="/search/:name" element={<SearchPage />} />


      <Route path="/login" element={<LoginPage />} />

      <Route
        path="/profile"
        element={
          <ProtectedRoute>
            <ProfilePage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/order"
        element={
          <ProtectedRoute>
            <OrderPage />
          </ProtectedRoute>
        }
      />
      <Route path="/form/:id" element={<UserOrderPage />} />
      <Route path="/pay/:id" element={<UserPayPage />} />

      <Route path="/order/success" element={<OrderSuccessPage />} />

      <Route path="/delivery" element={<DeliveryPage />} />
      <Route path="/contacts" element={<ContactsPage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/policy" element={<CorpPage />} />

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default AppNavigator;
