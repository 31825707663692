import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import StorageConstant from "../../constants/StorageConstant";

import Container from "../container/Container";
import UiBtnIcon from "../buttons/UiBtnIcon";
import UiCatalogCard from "../cards/UiCatalogCard";
import UiCatalogFilter from "./UiCatalogFilter";

import { useContainerDimensions } from "../../hooks/useContainerDimensions";

import "./UiCatalog.scss";

const filtersList = StorageConstant.TAGS_ARRAY_FILTER;

const UiCatalog = (props) => {
  const componentRef = useRef();
  const { width, height } = useContainerDimensions(componentRef);

  //const slideLine = screenW > 768 ? 4 : 2.25;
  const [slideLine, setSlideLine] = useState(4);
  //const slideGap = screenW > 768 ? 20 : 12;
  const [slideGap, setSlideGap] = useState(32);

  const [listIndex, setListIndex] = useState(999);



  useEffect(() => {
    setSlideLine(window.innerWidth > 768 ? 4 : 2.5);
    setSlideGap(window.innerWidth > 768 ? 32 : 16);
  }, [window.innerWidth]);

  useEffect(() => {
    setListIndex(props.minimized ? 12 : 999)
  }, [props.minimized]);

  const cardWidth = (width - (slideLine - 1) * slideGap) / slideLine;
  const slideWidth = cardWidth + slideGap;
  const [activeSlide, setActiveSlide] = useState(0);
  const [offsetX, setOffsetX] = useState(0);

  const [slidesArr, setSlides] = useState([]);
  const [translate, setTranslate] = useState(0);
  const [xDown, setXDown] = useState(null);
  const [yDown, setYDown] = useState(null);

  {/*const slideW = slidesArr.length > 4 ? width - 60 : width;*/ }
  const [slideW, setSlideW] = useState(slidesArr.length > 4 ? width - 60 : width);
  const [arrCount, setArrCount] = useState(0);

  const pricesList = [
    0,
    [0, 2000],
    [2000, 3000],
    [3000, 5000],
    [5000, 10000],
    [10000, 99999],
  ];

  const sizeList = [0, [20, 30], [30, 40], [40, 50], [50, 60]];

  let navigate = useNavigate();
  const [activeSize, setActiveSize] = useState(0);
  const [activePrice, setActivePrice] = useState(0);
  const [activeTag, setActiveTag] = useState(filtersList[0].text);

  function checkFilter(arr) {
    let _arr = [];
    arr.map((item) => {
      if (activePrice == 0 && activeTag == filtersList[0].text) {
        _arr.push(item);
      } else {
        if (activePrice > 0) {
          if (
            item.price >= pricesList[activePrice][0] &&
            item.price <= pricesList[activePrice][1]
          ) {
            _arr.push(item);
          }
        } else if (activeTag != filtersList[0].text) {
          if (item?.status?.includes(activeTag)) {
            _arr.push(item);
          }
        }
      }
    }); /*
    arr.map((item) => {
      if(activeSize == 0 ){
        _arr.push(item)
      } else if(item.price >= sizeList[activeSize][0] &&  item.price <= sizeList[activeSize][1]){
        _arr.push(item)
      }
    })
    */
    return _arr;
  }

  const changeSlide = (val) => {
    let direction = val;
    var curSlide = activeSlide;

    if (direction === "next") {
      if (curSlide < props.list.length - slideLine) {
        curSlide = curSlide + 1;
      } else {
        curSlide = 0;
      }
    } else if (direction === "prev") {
      if (curSlide > 0) {
        curSlide = curSlide - 1;
      } else {
        curSlide = props.list.length - slideLine;
      }
    }

    setOffsetX(-curSlide * slideWidth);
    setActiveSlide(curSlide);
  };



  if (width > 984) {
    var cardW = slideW / 4;
    var cardWOffset = 4;
  } else {
    var cardW = slideW / 2;
    var cardWOffset = 2;
  }

  useEffect(() => {
    setTranslate(-activeSlide * slideW);
  }, [width]);

  useEffect(() => {
    setSlideW(slidesArr.length > 1 ? width - 60 : width - 60);
    let c = 0;
    slidesArr.map((item) => {
      c += item.length;
    })
    setArrCount(c);
  }, [slidesArr]);

  useEffect(() => {
    let mainArr = [],
      arr = [],
      filterArr = [],
      _max = 10,
      max = 0;
    checkFilter(props.list).map((item, index) => {
      if ( _max > max) {
        max++;
        filterArr.push(item);
      }
    }); 
    filterArr.map((item, index) => {
      arr.push(item);
      if (arr.length == 4) {
        mainArr.push(arr);
        arr = [];
      }
    });
    if (arr.length > 0) {
      mainArr.push(arr);
      arr = [];
    }
    setSlides(mainArr);
  }, [props.list]);


  var catalogList = slidesArr.map((item, index) => {
    let sub = item.map((item2, index2) => {
      if (listIndex > index) {
        return (
          <UiCatalogCard
            key={index2}
            cardWidth={props.scroll ? cardWidth : null}
            image={item2.image}
            onClick={() => navigate(`${props.url ? props.url : "/product"}/${item2.title_url}`)}
            oldPrice={item2.oldPrice}
            price={item2.sale > 0 ? `от ${item2.sale}` : item2.price}
            sizeList={item2.sizeList}
            skeleton={item2.loader}
            status={item2.status}
            title={item2.title}
          />
        );
      }
    });

    return (
      <div key={index} className="catalog-slider-slide">
        {sub}
      </div>
    );
  });

  return (
    <section
      className={
        "catalog-wrap" +
        (props.scroll ? " scroll" : "") +
        (props.white ? " white" : "")
      }
    >
      <Container>
        <div className="catalog-title-wrap">
          <div className="catalog-title">
            <h3>{props.title}</h3>
            {props.subtitle ? <p>{props.subtitle}</p> : null}
          </div>

          {props.filters ? (
            <UiCatalogFilter
              onChangePrice={(val) => {
                setActivePrice(val);
              }}
              onChangeSize={(val) => {
                setActiveSize(val);
              }}
              onChange={(val) => {
                setActiveTag(filtersList[val].text);
              }}
            />
          ) : null}
        </div>
        <div ref={componentRef} className="catalog-list">
          <div
            className="catalog-list-scroll"
            style={{ transform: `translateX(${offsetX}px)` }}
          >
            {catalogList}
          </div>
          {catalogList.length == 0 ? (
            <p>Ничего не найдено. Попробуйте сбросить фильтр.</p>
          ) : null}
        </div>
        {(props.minimized && checkFilter(props.list).length > 12) && (
          <div className="catalog-minimized">
            <button className="catalog-minimized-load-btn" onsClick={() => setListIndex(999)}>
              <span>Показать ещё {checkFilter(props.list).length - 12} букетов</span>
            </button>
          </div>
        )}
        <div className="catalog-list-control arrow-left">
          <UiBtnIcon
            color="blue"
            fill="white"
            icon="arrow-left"
            iconSize={20}
            round={true}
            size={40}
            onClick={() => changeSlide("prev")}
          />
        </div>
        <div className="catalog-list-control arrow-right">
          <UiBtnIcon
            color="blue"
            fill="white"
            icon="arrow-right"
            iconSize={20}
            round={true}
            size={40}
            onClick={() => changeSlide("next")}
          />
        </div>
      </Container>
    </section>
  );
};

export default UiCatalog;
