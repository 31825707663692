import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import UiIcon from "../icon/UiIcon";

import "./UiMenuList.scss";

const UiMenuList = (props) => {
  let location = useLocation().pathname;

  const [subMenu, setSubMenuOpen] = useState(false);

  /**
   * clicks outside of ref
   */
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * if clicked on outside
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setSubMenuOpen(false);
          //alert("You clicked outside of ref!");
        }
      }
      // bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  var menuItems = props.menuList.map((item, index) => {
    if (item.subList) {
      var subItems = item.subList.map((item2, index2) => {
        return (
          <li
            key={index2}
            className={
              "nav-menu-item-list-item" +
              (location === item.page ? " active" : "")
            }
          >
            <a href={item2.page}>{item2.title}</a>
          </li>
        );
      });
    }

    return (
      <li
        key={index}
        className={
          "nav-menu-item" +
          (location === item.page ? " active" : "") +
          (item.subList ? " get-sub" : "")
        }
      >
        {item.subList ? (
          <button onClick={() => setSubMenuOpen(!subMenu)}>
            {item.title}
            <UiIcon fill="dark" icon="arrow-down" size={16} />
          </button>
        ) : (
          <a href={item.page}>{item.title}</a>
        )}

        {item.subList && (
          <ul className={"nav-menu-item-list" + (subMenu ? " active" : "")}>
            {subItems}
          </ul>
        )}
      </li>
    );
  });

  return (
    <ul
      ref={wrapperRef}
      className={"nav-menu-list" + (props.vertical ? " vertical" : "")}
    >
      {menuItems}
    </ul>
  );
};
export default UiMenuList;
