import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import demo1 from "../../assets/demo/13.jpeg";
import demo3 from "../../assets/demo/96512873.jpeg";
import demo4 from "../../assets/demo/55.jpeg";
import demo5 from "../../assets/demo/61797063.jpeg";

import Container from "../../components/container/Container";
import PageContainer from "../../components/container/PageContainer";
import UiCatalog from "../../components/catalog/UiCatalog";
import UiPageTitle from "../../components/header/UiPageTitle";
import UiModalProduct from "../../components/modals/UiModalProduct";

import "../../styles/catalog.scss";

import {
  getStorageList as getStorageListAction,
  getStorageListByCat as getStorageListByCatAction, 
} from "../../redux/modules/storage";
import {
  getCategoryListByTitle as getCategoryListByTitleAction
} from "../../redux/modules/category";

import { getMenuCategoryList as getMenuCategoryListAction } from "../../redux/modules/category";

import { convertToList } from "../../utilities/storage";

function CatalogPage(props) {
  let { title } = useParams();
  let { name } = useParams();

  const navigate = useNavigate();
  const [productActive, setProductActive] = useState(false);
  const [catName, setCatName] = useState(null);
  const [linksList, setLinksList] = useState([{ href: "/catalog", label: "Каталог" }]);


  const [catalogListOnline, setCatalogListOnline] = useState([
    { loader: true },
    { loader: true },
    { loader: true },
    { loader: true },
    { loader: true },
    { loader: true },
    { loader: true },
    { loader: true },
  ]);

  const [catalogList, setCatalogList] = useState([
    { title: "Розы PREMIUM", link: "/" },
    { title: "Розы STANDARD", link: "/" },
    { title: "Кустовые розы", link: "/" },
    { title: "Эквадорские розы", link: "/" },
    { title: "Авторские букеты", link: "/" },
    { title: "Букеты невесты", link: "/" },
    { title: "Монобукеты и композиции", link: "/" },
    { title: "Подарки", link: "/" },
  ]);
  const [homeCatalog, setHomeCatalog] = useState([]);

  async function _load(_name) {
    if(_name){
      const list = await props.getCategoryListByTitle(_name);
      if (list.catalog) setCatalogListOnline(convertToList(list.catalog));
      if (list.data) setCatName(list.data?.title);
      setLinksList([ { href: "/catalog", label: "Каталог" }, { href: `/catalog/${name}`, label: list.data?.title } ])
    } else {
      const list = await props.getStorageList();
      if (list) setCatalogListOnline(convertToList(list));
    }
    
  }

  useEffect(() => {
    if (name) { 
      _load(name); 
    } else {
      _load(null);
    }
  }, [name]);


  useEffect(() => {
    if (title) setProductActive(true)
  }, [title])


  let list = catalogList.map((item, index) => { });

  return (
    <PageContainer
      page="catalog"
      pageTitle={`Доставка цветов Южно-Сахалинск - ${catName}`}
      pageDescription={catName}
      pageKeywords=""
    >
      <UiPageTitle
        linksList={linksList}
      />
      <div className="catalog-page-wrap">
        <Container>
          <div className="catalog-list">{list}</div>
        </Container>
        <UiCatalog
          title="Каталог букетов"
          subtitle="Сделай неповторимый и оригинальный подарок"
          filters={true}
          list={catalogListOnline}
          url={`/catalog/${name}`}
        />
      </div>

      <UiModalProduct
        modalOpen={productActive}
        url={`/catalog/${name}`}
        modalClose={() => { 
          setProductActive(false)
          navigate(`/catalog/${name}`);
        }}
      />

    </PageContainer>
  );
}

export default connect(({ storage, category }) => ({ storage, category }), { 
  getStorageList: getStorageListAction,
  getCategoryListByTitle: getCategoryListByTitleAction,
  getStorageListByCat: getStorageListByCatAction,
  getMenuCategoryList: getMenuCategoryListAction,
})(CatalogPage);
