import React from "react";

import phones from "../../../assets/demo/phones.png";

import { ReactComponent as AppStoreLogo } from "../../../assets/social/app-store-badge.svg";
import { ReactComponent as GoogleLogo } from "../../../assets/social/google-play-badge.svg";
import { ReactComponent as SvgLogoWhite } from "../../../assets/logo/circle-white.svg";

import Container from "../../../components/container/Container";

import "./UiHomeMobileInfo.scss";

const UiHomeMobileInfo = (props) => {
  return (
    <section className="home-mobile-info-wrap">
      <Container>
        <div className="home-about">
          <div className="home-about-info">
            <h2>
              Скачай наше приложение Flowery
              <br />
              <span>и получи 200 баллов на свой счет</span>
            </h2>
            <p>
              В нашем приложении заказывать любимые букеты стало ещё проще
              и быстрее, а на первый заказ из приложения вы получите 200 бонусов
            </p>
            <div className="home-mobile-links-wrap">
              <p>СКАЧАТЬ ПРИЛОЖЕНИЕ:</p>
              <div className="home-mobile-links-stores">
                <GoogleLogo width={142} />
                <AppStoreLogo width={142} />
              </div>
            </div>
          </div>
          <div className="home-about-info">
            <img className="home-about-info-phones" src={phones} />
          </div>
        </div>
      </Container>
      <div className="home-mobile-links">
        <Container>
          <SvgLogoWhite className="home-mobile-links-logo1" />
          <SvgLogoWhite className="home-mobile-links-logo2" />
        </Container>
      </div>
    </section>
  );
};
export default UiHomeMobileInfo;
