import React, { useState, useEffect } from "react";

import demo1 from "../../assets/demo/13.jpeg";
import demo3 from "../../assets/demo/96512873.jpeg";
import demo4 from "../../assets/demo/55.jpeg";
import demo5 from "../../assets/demo/61797063.jpeg";
import demo6 from "../../assets/demo/demo.webp";

import { ReactComponent as SvgLogo } from "../../assets/logo/circle.svg";

import UiBtnColor from "../buttons/UiBtnColor";
import UiCheckList from "../forms/UiCheckList";
import UiModal from "./UiModal";
import UiTextInputMasked from "../forms/UiTextInputMasked";

import "./UiQuiz.scss";

const UiQuiz = (props) => {
  const [done, setDone] = useState(false);
  const [phone, setPhone] = useState(null);
  const [activeSlide, setActiveSlide] = useState(0);
  const [slideList, setSlideList] = useState([
    {
      title: "Кому Вы хотите подарить букет?",
      list: [
        { option: "Маме", image: demo1 },
        { option: "Подруге", image: demo3 },
        { option: "Любимой девушке", image: demo4 },
        { option: "Коллеге", image: demo5 },
        { option: "Для учителя", image: demo6 },
        { option: "Другой ответ", image: demo1 },
      ],
      text: "Для флориста желательно знать, кому вы собираетесь дарить подарок. Как правило это влияет на тип используемых цветов и «характер» букета.",
    },

    {
      title: "Какие предпочтения у нее/него по цветам?",
      list: [
        { option: "Розы", image: demo1 },
        { option: "Гипсофила", image: demo1 },
        { option: "Гортензии", image: demo1 },
        { option: "Хризантемы", image: demo1 },
        { option: "Гвоздики", image: demo1 },
        { option: "Другой ответ", image: demo1 },
      ],
      text: "У всех вкусы разные. Если Вы знаете, что дорогой Вам человек любит, например, красные розы - именно их и стоит подарить.",
    },

    {
      title: "На какой бюджет Вы рассчитываете?",
      list: [
        { option: "от 2000 до 3000", image: demo1 },
        { option: "от 3000 до 4000", image: demo1 },
        { option: "от 4000 до 5000", image: demo1 },
        { option: "свыше 5000", image: demo1 },
      ],
      text: "Очень важный вопрос в случае, если вы планируете определенный бюджет на букет. Мы подберем несколько вариантов для Вас в зависимости от указанной суммы.",
    },

    {
      title: "Какой повод для подарка?",
      list: [
        { option: "День Рождения", image: demo1 },
        { option: "Свадьба", image: demo1 },
        { option: "Комплимент", image: demo1 },
        { option: "Свидание", image: demo1 },
        { option: "Рождение ребенка", image: demo1 },
        { option: "Другой ответ", image: demo1 },
      ],
      text: "Разумеется идеальный букет должен полностью соответствовать поводу. И для некоторых событий подойдет практически любой вариант, но для других нужно что-то особенное.",
    },

    {
      title: "Какое оформление букета вам нравится?",
      list: [
        { option: "Дизайнерский букет", image: demo1 },
        { option: "Монобукет (цветы одного вида)", image: demo1 },
        { option: "Цветы в коробке", image: demo1 },
        { option: "Классические букеты", image: demo1 },
      ],
      text: "Аксессуары для оформления букетов - бумага, ткань, корзинки, ...",
    },

    {
      title: "Когда нужен букет?",
      list: [
        { option: "Сегодня", image: demo1 },
        { option: "Завтра", image: demo1 },
        { option: "На неделе", image: demo1 },
        { option: "Другой ответ", image: demo1 },
      ],
      text: "Укажите время когда вы бы хотели получить букет",
    },

    {
      title: "Какую упаковку букета Вы предпочтете?",
      list: [
        { option: "Букет в фирменной коробке", image: demo1 },
        { option: "Букет в фирменной упаковке", image: demo1 },
        { option: "Простая лента в тон", image: demo1 },
        { option: "Упаковка не нужна", image: demo1 },
      ],
      text: "Мы расскажем какие виды упаковки бывают, как мы подбираем ее и как вам легко соориентироваться при покупке букета цветов",
    },
  ]);
  const [selectedQuestionNum, setSelectedQuestionNum] = useState(null);
  const [result, setResult] = useState([]);
  const [isError, setIsError] = useState(false);

  const nextSlide = () => {
    console.log(result)
    if (activeSlide < slideList.length) {
      setIsError(false)
      if (selectedQuestionNum !== null) {
        let num = activeSlide + 1;
        setActiveSlide(num);
        let _res = result;
        _res[activeSlide] = selectedQuestionNum;
        setResult(_res)
        setSelectedQuestionNum(null)
      } else {
        setIsError(true)
        setTimeout(() => setIsError(false), 500)
      }

    } else {
      setIsError(false)
      if (phone !== null) {
        setDone(true)
      } else {
        setIsError(true)
        setTimeout(() => setIsError(false), 500)
      }

    }


  };

  const prevSlide = () => {
    if (activeSlide > 0) {
      let num = activeSlide - 1;
      setActiveSlide(num);
      setDone(false);
    }
  };

  if (slideList.length > 0) {
    var slides = slideList.map((item, index) => {
      return (
        <div
          key={index}
          className={
            "modal-quiz-slide" + (index === activeSlide ? " active" : "")
          }
        >
          <h3>{item.title}</h3>
          <div className="modal-quiz-slide-check">
            <UiCheckList
              colored={true}
              checkList={item.list}
              onChange={(val) => {
                setSelectedQuestionNum(val)
              }}
            />
            <div
              className="modal-quiz-slide-info"
              style={{
                backgroundImage: "url(" + item.list[1].image + ")",
              }}
            >
              <div className="modal-quiz-expert">
                <div className="modal-quiz-expert-image">
                  <SvgLogo width={52} height={52} />
                </div>
                <p>{item.text}</p>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  return (
    <UiModal scroll={true} show={props.modalOpen}>
      <div className="modal-quiz">
        <div className="modal-quiz-title">
          <div className="modal-quiz-title-text">
            <h2>Подобрать букет</h2>
            <p>
              Ответьте на несколько вопросов, чтобы мы помогли вам выбрать букет
            </p>
          </div>
          <UiBtnColor
            color="light"
            onClick={() => props.modalClose()}
            small={true}
            text="Отмена"
          />
        </div>
        <div className="modal-quiz-content">
          {activeSlide < slideList.length && (
            <div className="modal-quiz-number">
              <p>Вопрос {activeSlide + 1} из 7</p>
            </div>
          )}
          <div className="modal-quiz-slider">
            {activeSlide < slideList.length ? (
              slides
            ) : (
              <div className="modal-quiz-slider-final">
                {done ? (
                  <div className="modal-quiz-slider-final-wrap">
                    <h3>Отлично!</h3>
                    <p>
                      Мы рады, что Вы решили обратиться за помощью к нашим
                      флористам. В ближайшее время с Вами свяжется наш
                      сотрудник!
                    </p>
                  </div>
                ) : (
                  <div className="modal-quiz-slider-final-wrap">
                    <h3>Почти все готово!</h3>
                    <p>
                      Для получения консультации от нашего флориста нам нужно
                      знать Ваш номер телефона. Войдите в свой профиль на сайте
                      или введите номер телефона ниже:
                    </p>
                    <UiTextInputMasked
                      //label="Мой телефон"
                      placeholder="+7 (000) 000 00-00"
                      onChange={(v) => setPhone(v)}
                      disabled={false}
                    />
                    <UiBtnColor color="pink" small text="Узнать результат" onClick={() => {
                      setDone(true);
                    }} />
                    <p>
                      <span>
                        Нажимая на кнопку "Узнать результат" Вы соглашаетесь с{" "}
                        <a>условиями обработки персональных данных</a>
                      </span>
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {!done ? (
          <div className="modal-quiz-control">
            <UiBtnColor color="light" onClick={() => prevSlide()} text="Назад" />
            <UiBtnColor
              color={isError ? "red" : "pink"}
              onClick={() => nextSlide()}
              error={isError}
              text="Ответить"
            />
          </div>
        ) : (
          <div className="modal-quiz-control">
            
            <UiBtnColor
              color={isError ? "red" : "pink"}
              onClick={() => props.modalClose()}
              error={isError}
              text="Закрыть"
            />
          </div>
        )}
      </div>
    </UiModal>
  );
};

export default UiQuiz;
