import React from "react";
import UiBtnIcon from "../../components/buttons/UiBtnIcon";

import Container from "../../components/container/Container";
import PageContainer from "../../components/container/PageContainer";
import UiIcon from "../../components/icon/UiIcon";

import "./Static.scss";

function ContactsPage() {
  return (
    <PageContainer page="contacts" pageTitle="Доставка цветов Южно-Сахалинск">
      <Container>
        <div className="contacts-wrap">
          <div className="contacts-info">
            <h2>Контакты</h2>
            <div className="contacts-info-list">
              <div className="contacts-info-item">
                <UiIcon fill="pink" icon="call" size={20} />
                <h3>
                  <a href="tel:+78003334409">+7 (800) 333-44-09</a>
                </h3>
              </div>
              <p>
                <span>Каждый день с 06:00 до 01:00</span>
              </p>
              <div className="contacts-info-item">
                <UiIcon fill="pink" icon="message" size={20} />
                <h4>
                  <a href="mailto:administrator@dutyfreerose.ru">
                    administrator@dutyfreerose.ru
                  </a>
                </h4>
              </div>
              <p><strong>Оформить заказ:</strong></p>
              <div className="contacts-info-item">
                <UiIcon fill="pink" icon="message" size={20} />
                <h4>
                  <a href="mailto:operator@dutyfreerose.ru">
                    operator@dutyfreerose.ru
                  </a>
                </h4>
              </div>
              <p><strong>Отдел маркетинга:</strong></p>
              <div className="contacts-info-item">
                <UiIcon fill="pink" icon="message" size={20} />
                <h4>
                  <a href="mailto:marketolog@dutyfreerose.ru">
                    marketolog@dutyfreerose.ru
                  </a>
                </h4>
              </div>
              <div className="contacts-info-text">
                <p>Мы всегда рады обратной связи!</p>
                <p>
                  Наши сотрудники ежедневно с 06:00 до 01:00 готовы принять ваш
                  заказ и помочь вам с любыми возникшими вопросами.
                </p>
                <p>
                  Вы также можете выразить свои пожелания и замечания касательно
                  качества наших товаров, доставки и работе магазинов.
                </p>
              </div>
              <h3>Наши магазины в Южно-Сахалинске</h3>
              <div className="contacts-info-item">
                <UiIcon fill="pink" icon="pin2" size={20} />
                <h4>
                  <a>Южно-Сахалинск, проспект Победы, 9Б</a>
                  <span>с 09:00 до 20:00, цокольный этаж</span>
                </h4>
              </div>
              <div className="contacts-info-item">
                <UiIcon fill="pink" icon="pin2" size={20} />
                <h4>
                  <a>Южно-Сахалинск, улица Сахалинская, 45</a>
                  <span>Круглосуточно, 1 этаж</span>
                </h4>
              </div>
              <div className="contacts-info-item">
                <UiIcon fill="pink" icon="pin2" size={20} />
                <h4>
                  <a>Южно-Сахалинск, улица Сахалинская, 69</a>
                  <span>​ТРЦ Калинка, круглосуточно</span>
                </h4>
              </div>
              <div className="contacts-info-item">
                <UiIcon fill="pink" icon="pin2" size={20} />
                <h4>
                  <a>Южно-Сахалинск, улица Пограничная, 31А</a>
                  <span>ТЦ Лотте, с 10:00 до 19:00</span>
                </h4>
              </div>
              <div className="contacts-info-item">
                <UiIcon fill="pink" icon="pin2" size={20} />
                <h4>
                  <a>Южно-Сахалинск, улица Ленина, 219</a>
                  <span>Круглосуточно</span>
                </h4>
              </div>
            </div>

            <div className="contacts-info-req">
              <h3>Реквизиты:</h3>
              <p>ИП ГАЛИУЛЛИНА ДИАНА НИКОЛАЕВНА</p>
              <p>ИНН 650112190630</p>
              <p>ОГРНИП 321650100015109</p>
              <p>РАС.СЧЕТ 40802810550340017626</p>
              <p>БИК 040813608</p>
              <p>ДАЛЬНЕВОСТОЧНЫЙ БАНК</p>
              <p>ПАО СБЕРБАНК</p>
              <p>КОРР.СЧЕТ 30101810600000000608</p>
              <p>ЮРИДИЧЕСКИЙ АДРЕС</p>
              <p>Г.ЮЖНО-САХАЛИНСК, С.ДАЛЬНЕЕ</p>
              <p>УЛ.СТУДЕНЧЕСКАЯ 15-4</p>
              <p><a href="mailto:Natalia.Prisekina@gmail.com">Natalia.Prisekina@gmail.com</a></p>
              <p><a href="tel:89241910332">89241910332</a></p>
              <p>Юр и факт адрес:  г.Шахтерск, ул. Интернациональная 13-20.</p>
            </div>
          </div>
          <div className="contacts-map">
            <h3>Напишите нам сообщение:</h3>
            <div className="contacts-icons">
              <UiBtnIcon
                color="white"
                fill="dark"
                icon="telegram"
                iconSize={28}
                size={44}
                href="https://t.me/Flowerybot"
              />
              <UiBtnIcon
                color="white"
                fill="dark"
                icon="whatsapp"
                iconSize={28}
                size={44}
                href="https://api.whatsapp.com/send/?phone=%2B79147541334&text&type=phone_number&app_absent=0"
              />
              <UiBtnIcon
                color="white"
                fill="dark"
                icon="vk"
                iconSize={28}
                size={44}
                href="https://vk.com/flowery_sakhalin"
              />
            </div>
            <iframe
              src="https://yandex.ru/map-widget/v1/?um=constructor%3A58efcc0a7ad3a7cdb1fe1b0abcb6b9ad56c341dd20c995fb5986f58652a9b576&amp;source=constructor"
              width="100%"
              height="500"
              frameborder="0"
            ></iframe>
          </div>
        </div>
      </Container>
    </PageContainer>
  );
}

export default ContactsPage;
