import React from "react";
import Skeleton from "react-loading-skeleton";

import UiBtnIcon from "../../components/buttons/UiBtnIcon";

import "react-loading-skeleton/dist/skeleton.css";
import "./UiCatalogCard.scss";

const UiCatalogCard = (props) => {
  if (!props.skeleton && props.status?.length > 0) {
    var statuses = props.status.map((item, index) => {
      return (
        <p
          key={index}
          className={
            "status" +
            (item == "Новинка"
              ? " pink"
              : item == "Хит продаж"
              ? " orange"
              : item == "Авторский"
              ? " green"
              : " opal")
          }
        >
          {item}
        </p>
      );
    });
  }

  if (!props.skeleton && props.sizeList?.length > 0) {
    var sizes = props.sizeList.map((item, index) => {
      return (
        <button key={index} className="active">
          {item}
        </button>
      );
    });
  }

  return ( 
    <div
      className={"catalog-card" + (props.search ? " search" : "")}
      onClick={props.onClick}
      style={props.cardWidth ? { width: props.cardWidth } : null}
    >
      <div className="catalog-card-image">
        <div className="catalog-card-image-wrap">
          {props.skeleton ? (
            <Skeleton className="catalog-card-image-wrap" />
          ) : (
            <img src={props.image} />
          )}
        </div>
        <div className="catalog-card-controls">
          {!props.skeleton && props.status?.length > 0 && (
            <div className="catalog-card-statuses">{statuses}</div>
          )}
        </div>
        {!props.skeleton && props.sizeList?.length > 0 && (
          <div className="catalog-card-sizes">{sizes}</div>
        )}
      </div>
      <div className="catalog-card-info">
        <div className="catalog-card-info-wrap">
          <h3>
            {props.skeleton ? <Skeleton count={1} width={136} /> : props.title}
          </h3>
          <div
            className={
              "catalog-card-price" +
              (props.oldPrice ? " catalog-card-price-sale" : "")
            }
          >
            {props.skeleton ? (
              <Skeleton count={1} width={60} />
            ) : (
              <p>
                {props.oldPrice && <span>{props.oldPrice}&nbsp;₽</span>}
                {props.oldPrice && <b>&nbsp;&nbsp;&nbsp;</b>}
                {props.price}&nbsp;₽
              </p>
            )}
            {props.search ? null : props.skeleton ? (
              <Skeleton width={80} height={32} />
            ) : (
              <UiBtnIcon
                color="blue"
                hover="pink"
                icon="bag"
                fill="white"
                round
                iconSize={20}
                size={36}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default UiCatalogCard;
