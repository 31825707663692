import React from "react";

import { ReactComponent as SvgInstagram } from "../../assets/social/instagram.svg";
import { ReactComponent as SvgLogo } from "../../assets/logo/logo.svg";
import { ReactComponent as SvgVk } from "../../assets/social/vk.svg";

import Container from "../container/Container";
import UiIcon from "../icon/UiIcon";

import "./UiFooter.scss";

const UiFooter = (props) => {
  const nowYear = new Date().getFullYear();

  var menu = props.menuList.map((item, index) => {
    return (
      <div key={index} className="footer-link-item">
        <a href={item.page}>{item.title}</a>
      </div>
    );
  });

  var menu2 = props.menuList2.map((item, index) => {
    return (
      <div key={index} className="footer-link-item">
        <a href={item.page}>{item.title}</a>
      </div>
    );
  });

  return (
    <footer className="footer">
      <Container>
        <div className="footer-inner">
          <div className="footer-wrap footer-logo-wrap">
            <div className="footer-logo-info">
              <p>
                <strong>FLOWERY</strong>
                <br />
                <br />
              </p>
              <p>Доставка цветов в Южно-Сахалинске</p>
              <p>Цветочная лавка в Южно-Сахалинске</p>
            </div>
            <div className="footer-link-item">
              <a href="https://yandex.ru/maps/-/CCUzJ8w3~B">
                <UiIcon fill="pink" icon="pin2" size={12} />{" "}
                <span>Проспект Победы, 9Б</span>
              </a>
              <a href="https://yandex.ru/maps/-/CCUzJ8HHgD">
                <UiIcon fill="pink" icon="pin2" size={12} />{" "}
                <span>Улица Сахалинская, 45</span>
              </a>
              <a href="https://yandex.ru/maps/-/CCUzJ8T9CC">
                <UiIcon fill="pink" icon="pin2" size={12} />{" "}
                <span>ТЦ Калинка - Сахалинская 69</span>
              </a>
              <a href="https://yandex.ru/maps/-/CCUzJ8XhoA">
                <UiIcon fill="pink" icon="pin2" size={12} />{" "}
                <span>ТЦ Лотте - Пограничная ул., 31А</span>
              </a>
              <a href="https://yandex.ru/maps/-/CCUCrIhI9A">
                <UiIcon fill="pink" icon="pin2" size={12} />{" "}
                <span>Улица Ленина, 219</span>
              </a>
            </div>
          </div>
          <div className="footer-wrap footer-menu-wrap">
            <div className="footer-social">
              <a
                className="footer-social-link"
                href="https://vk.com/flowery_sakhalin"
              >
                <SvgVk className="footer-social-icon" />
              </a>
              <a className="footer-phone" href="tel:+78003334409">+7 (800) 333-44-09</a>
            </div>
            <div className="footer-menu">
              <nav className="footer-menu-nav">{menu}</nav>
              <nav className="footer-menu-nav">{menu2}</nav>
            </div>
            <div className="footer-link-item">
              <a href="/policy">Политика конфиденциальности</a>
            </div>
          </div>
        </div>
      </Container>
      <div className="sub-footer">
        <Container>
          <div className="sub-footer-wrap">
            <p>
              Copyright © {nowYear} - All rights reserved. Сайт разработан{" "}
              <a href="http://devkot.ru/index_ru.html">DEVKOT</a>
            </p>
          </div>
        </Container>
      </div>
    </footer>
  );
};
export default UiFooter;
