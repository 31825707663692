import React, { useState, useEffect } from "react";

import "./UiNotify.scss";


const UiNotify = (props) => {

     const options = {
        message: `<p>${props.title}</p>`,
        dismiss: "Ок"
    }

    function run() { 
            show() 
            setTimeout(() => {
                dismiss()
            }, 3000);
    }

    function dismiss() {
        var notice = document.getElementById('cookie-notice')
        if (notice) notice.parentNode.removeChild(notice) 
    }

    function undismiss() {
        delete window.localStorage.cookieNoticeDismissed
    }

    function show() {
        var $div = document.createElement('div')
        $div.className = 'cookie-notice'
        $div.id = 'cookie-notice'

        var $message = document.createElement('div')
        $message.className = 'cookie-notice-message'
        $message.innerHTML = options.message
        $div.appendChild($message)

        var $dismiss = document.createElement('button')
        $dismiss.innerHTML = options.dismiss
        $dismiss.onclick = dismiss
        $div.appendChild($dismiss) 
        if(document.getElementById("page")) document.getElementById("page").appendChild($div); else  document.body.appendChild($div);
       
    }
 


    useEffect(() => {
        if(props.show) run()
    }, [props.show])

    return null;
};

export default UiNotify;
