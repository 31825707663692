import * as React from "react";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Provider } from "react-redux";

import { ReactComponent as SvgLogoWhite } from "./assets/logo/circle-white.svg";

import AppInfoLoader from "./components/main/AppInfoLoader";
import AppModalComponent from "./components/main/AppModalComponent";

import UiBtnColor from "./components/buttons/UiBtnColor";
import UiBtnIcon from "./components/buttons/UiBtnIcon";
import UiBtnTopScroll from "./components/buttons/UiBtnTopScroll";
import UiIcon from "./components/icon/UiIcon";
import UiHeader from "./components/header/UiHeader";
import UiFooter from "./components/footer/UiFooter";
import UiQuiz from "./components/modals/UiQuiz";
import UiSearch from "./components/modals/UiSearch";

import { useWindowDimensions } from "./hooks/useWindowDimensions";
import AppNavigator from "./navigation/AppNavigator";

import configureStore from "./redux/store";

import "./styles/app.scss";

function App() {
  const store = configureStore();
  const { width, height } = useWindowDimensions();
  const sliderH = height - 72;

  const [modal, setModal] = useState(false);
  const [modalQuizOpen, setModalQuizOpen] = useState(false);
  const [rightPanelOpen, setRightPanelOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);

  const [headerStick, setHeaderStick] = useState(false);
  const [quizBtnShow, setQuizBtnShow] = useState(false);
  const [topBtnActive, setTopBtnActive] = useState(false);

  const [scrollPosition, setScrollPosition] = useState(0);

  const scrollRef = useRef(null);

  let location = useLocation().pathname;

  const handleScroll = () => {
    const position = window.pageYOffset;
    //console.log(window.pageYOffset);
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useLayoutEffect(() => {
    executeScroll();
    if (location.includes("quiz")) setModalQuizOpen(true);
  }, [location]);

  useEffect(() => {
    if (scrollPosition > 146) {
      setTopBtnActive(true);
      setHeaderStick(true);
    } else {
      setTopBtnActive(false);
      setHeaderStick(false);
    }

    if (scrollPosition > 420) {
      setQuizBtnShow(true);
    } else {
      setQuizBtnShow(false);
    }
  }, [scrollPosition]);

  useEffect(() => {}, []);

  const setMenuOpen = () => {
    let new_v = !rightPanelOpen;
    setRightPanelOpen(new_v);
  };

  const executeScroll = () => window.scrollTo({ top: 0, behavior: "smooth" });
  const smoothScroll = () => window.scrollTo({ top: 0, behavior: "smooth" });

  const menuList1 = [
    {
      page: "/catalog/rozy_premium",
      title: "Розы",
      subList: [
        { page: "/catalog/rozy_premium", title: "Розы PREMIUM" },
        { page: "/catalog/rozy_standard", title: "Розы STANDARD" },
        { page: "/catalog/kustovyye_rozy", title: "Кустовые розы" },
        { page: "/catalog/ekvadorskiye_rozy", title: "Эквадорские розы" },
      ],
    },
    { page: "/catalog/avtorskie_buketi", title: "Авторские букеты" },
    { page: "/catalog/svadebnie_buketi", title: "Свадебные букеты" },
    { page: "/catalog/monobuketi", title: "Монобукеты" },
    { page: "/catalog/podarki", title: "Подарки" },
  ];
  const menuList2 = [
    { page: "/delivery", title: "Доставка и оплата" },
    { page: "/contacts", title: "Контакты" },
    { page: "/about", title: "О нас" },
  ];

  return (
    <Provider store={store}>
      <AppInfoLoader />
      <div className="app" id="app">
        <div className="app-scroll">
          <UiHeader
            menuList1={menuList1}
            menuList2={menuList2}
            onChangeMenu={() => setMenuOpen()}
            onSearchOpen={() => {
              setSearchOpen(true);
            }}
            stick={headerStick}
          />
          <AppNavigator />
          <UiFooter menuList={menuList1} menuList2={menuList2} />
        </div>
        <div id="fixedElem" className="app-msgs">
          <button className="app-msgs-btn">
            <UiIcon fill="white" icon="chat" size={20} />
          </button>
          <div className="app-msgs-list">
            <UiBtnIcon
              color="whatsapp"
              href="https://api.whatsapp.com/send/?phone=%2B79147541334&text&type=phone_number&app_absent=0"
              fill="white"
              icon="whatsapp"
              iconSize={24}
              round
              size={36}
            />
            <UiBtnIcon
              color="telegram"
              href="https://t.me/Flowerybot"
              fill="white"
              icon="telegram"
              iconSize={24}
              round
              size={36}
            />
            <UiBtnIcon
              color="instagram"
              href="https://instagram.com/flowery_sakhalin/"
              fill="white"
              icon="instagram"
              iconSize={24}
              round
              size={36}
            />
          </div>
        </div>
        <div className={"quiz-button-wrap" + (quizBtnShow ? " active" : "")}>
          <button
            onClick={() => setModalQuizOpen(true)}
            className="btn quiz-button"
          >
            <SvgLogoWhite className="quiz-button-icon" />
            <span>Подобрать букет</span>
          </button>
        </div>
        <UiBtnTopScroll active={topBtnActive} onClick={() => smoothScroll()} />
        <UiQuiz
          modalOpen={modalQuizOpen}
          modalClose={() => setModalQuizOpen(false)}
        />
        <UiSearch
          modalOpen={searchOpen}
          modalClose={() => setSearchOpen(false)}
        />
        {/** modals */}
        <AppModalComponent />
      </div>
    </Provider>
  );
}

export default App;
