import React, { Component } from 'react';

import './UiTextArea.scss';

class UiTextArea extends Component {

  state = {
  }

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="form-group">
        <label>{this.props.label}</label>
        <textarea 
          disabled={this.props.disabled}
          placeholder={this.props.placeholder}
          rows="3"
          className="text-area"
          onChange={e => this.props.onChange(e.target.value)}
        ></textarea>
      </div>
    );
  }
}
export default UiTextArea;