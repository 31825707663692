import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import Container from "../../components/container/Container";
import PageContainer from "../../components/container/PageContainer";
import UiBtnColor from "../../components/buttons/UiBtnColor";
import UiCheck from "../../components/forms/UiCheck";
import UiPageTitle from "../../components/header/UiPageTitle";
import UiTextInput from "../../components/forms/UiTextInput";
import UiTextInputMasked from "../../components/forms/UiTextInputMasked";

import "./Login.scss";

import {
  loginUser as loginUserAction,
  registerUser as registerUserAction,
  changeLoaderState as changeLoaderStateAction,

  loginTestUser as loginTestUserAction
} from "../../redux/modules/login";

import { storeData, retrieveData } from "../../services/Storage";

function LoginPage(props) {
  let navigate = useNavigate();

  const [phone, setPhone] = useState(null);
  const [ePhone, setEPhone] = useState(null);
  const [code, setCode] = useState(null);
  const [isSend, setIsSend] = useState(false);
  const [agree, setAgree] = useState(true);

  /*
  useEffect(() => {
    if (props.login.user) {
      navigate(`/profile`)
    }
  }, [props.login.user]);
*/

  function sendCode() {
 
    if (phone) {
      props.changeLoaderState(true);

      var _phone = phone.replace(/ /g, "");
      _phone = _phone.replace(/\+7/g, "7");
      _phone = _phone.replace(/\(/g, "");
      _phone = _phone.replace(/\)/g, "");
      _phone = _phone.replace(/-/g, "");

      props.registerUser(_phone).then((val) => {
        props.changeLoaderState(false);
        setIsSend(true);
        setEPhone(_phone);
      });
    }

  }

  function login() {

    props.changeLoaderState(true);
    props.loginUser(ePhone, code).then((val) => {
      props.changeLoaderState(false);

      if (val) {
        if (retrieveData("from_cart")) {
          storeData("from_cart", null);
          navigate(`/order`);
        } else navigate(`/profile`);
      }
    });  
  }

  return (
    <PageContainer page="profile" pageTitle="Доставка цветов Южно-Сахалинск">
      <UiPageTitle
        linksList={[{ href: "/login", label: "Войти в профиль" }]}
      />
      <Container>
        <div className="login-wrap">
          <div className="login-info">
            <UiTextInputMasked
              label="Мой телефон"
              placeholder="+7 (000) 000 00-00"
              onChange={(v) => setPhone(v)}
              disabled={isSend}
            />

            {isSend ? (
              <UiTextInput
                label="Код из СМС"
                placeholder="1234"
                type="number"
                onChange={(v) => setCode(v)}
              />
            ) : null}

            {!isSend ? (
              <UiCheck
                label="Даю согласие на обработку и хранение персональных данных"
                onChange={() => {
                  setAgree(!agree);
                }}
                value={agree}
              />
            ) : null}
            <p>
              После нажатия кнопки "получить код" вам придет СМС сообщение с кодом на указанный
              номер
            </p>
            {!isSend ? (
              <UiBtnColor
                color={agree ? "pink" : "gray"}
                text="Получить код"
                onClick={() => sendCode()}
                disabled={!agree}
              />
            ) : (
              <UiBtnColor color="pink" text="Войти" onClick={() => login()} />
            )}
          </div>
        </div>
      </Container>
    </PageContainer>
  );
}

export default connect(({ login }) => ({ login }), {
  loginTestUser: loginTestUserAction,

  loginUser: loginUserAction,
  registerUser: registerUserAction,
  changeLoaderState: changeLoaderStateAction,
})(LoginPage);
