import Env from "../services/Env";
import { convertImages } from "./array";

import demo1 from "../assets/demo/demo5.webp";

export const getImage = (img) => {
  let imgs = convertImages(img);
  return imgs.length > 0 ? Env.PUBLIC_URL + imgs[0] : demo1;
};

export const convertToList = (_arr) => {
  let arr = [];
  _arr.map((item) => { 
    let imgs = convertImages(item.imgs);
    arr.push({
      id: item.id,
      image: imgs.length > 0 ? Env.PUBLIC_URL + imgs[0] : demo1,
      title: item.title,
      title_url: item.title_url,
      price: item.price,
      status:   (item.tags ? item.tags.split(",") : []),
      sale: item.sale,
      count: 1,
      loader:  item.price ? false : true
    });
  });
  return arr;
};

export const reorderBasket = (_arr) => {
  let arr = [];
  _arr.map((item) => {
    let f = -1;
    arr.map((item2, index2) => {
      if (item.title == item2.title) f = index2;
    });
    if (f == -1) {
      item.count = 1;
      arr.push(item);
    } else {
      arr[f].count++;
    }
  });
  return arr;
};

export const increaseBasket = (_arr, _id, _v) => {
  let f = -1;
  _arr.map((item, index) => {
    if (item.id == _id) item.count = _v;
    if (item.id == _id && _v == 0) f = index;
    if (item.id == _id && item.count == 0) f = index;
  });
  if (f !== -1) _arr.splice(f, 1);

  return _arr;
};
