import React from "react";
import UiIcon from "../icon/UiIcon";

import "./UiBtnIcon.scss";

const UiBtnIcon = (props) => {
  return (
    <a
      className={
        "btn btn-icon" +
        (props.color ? " " + props.color : "") +
        (props.hover ? " hover-" + props.hover : "") +
        (props.round ? " round" : "")
      }
      href={props.href}
      onClick={props.onClick}
      disabled={props.disabled}
      style={{ width: props.size, height: props.size }}
      target={props.target}
    >
      <UiIcon icon={props.icon} fill={props.fill} size={props.iconSize} />
    </a>
  );
};
export default UiBtnIcon;
