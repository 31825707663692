import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";

import { ReactComponent as SvgBag } from "../../assets/ui/bag.svg";

import {
  getStorageListByCat as getStorageListByCatAction,
  getStorageItem as getStorageItemAction,
} from "../../redux/modules/storage";
import {
  checkInUserBasket as checkInUserBasketAction,
  addUserBasket as addUserBasketAction,
  getStoredUser as getStoredUserAction,
  getStoredUserBasket as getStoredUserBasketAction,
  getStoredBasket as getStoredBasketAction,
  clearUserBasket as clearUserBasketAction,
  changeUserBasket as changeUserBasketAction,
} from "../../redux/modules/login";
import {
  getSettingList as getSettingListAction,
  getSettingValue as getSettingValueAction,
  getSettingDelivery as getSettingDeliveryAction,
} from "../../redux/modules/setting";
import { addOrder as addOrderAction } from "../../redux/modules/order";

import OrderConstant from "../../constants/OrderConstant";
import { getPrice, getTotalPrice } from "../../utilities/order";
import { toOptionsList, toArrayList } from "../../utilities/array";
import { formatDate } from "../../utilities/date";

import Container from "../../components/container/Container";
import PageContainer from "../../components/container/PageContainer";
import UiBasketCard from "../../components/cards/UiBasketCard";
import UiBtnColor from "../../components/buttons/UiBtnColor";
import UiCheckList from "../../components/forms/UiCheckList";
import UiCheck from "../../components/forms/UiCheck";
import UiTextInput from "../../components/forms/UiTextInput";
import UiTextInputMasked from "../../components/forms/UiTextInputMasked";
import UiTextArea from "../../components/forms/UiTextArea";
import UiSelectOption from "../../components/forms/UiSelectOption";

import UiMap from "../../components/map/UiMap";
import UiTextInputSV from "../../components/forms/UiTextInputSV";
import UiSelectDate from "../../components/forms/UiSelectDate";
import UiPageTitle from "../../components/header/UiPageTitle";

import UiGiftCard from "../../components/cards/UiGiftCard";

import UiLoader from "../../components/modals/UiLoader";
import UiNotify from "../../components/modals/UiNotify";

import "./Order.scss";

import { convertToList } from "../../utilities/storage";
function OrderPage(props) {
  let navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [settings, setSettings] = useState([]);
  const [basketList, setBasket] = useState([]);
  const [gifts, setGifts] = useState([]);

  const [discount, setDiscount] = useState(0);
  const [deliveryPrice, setDP] = useState(250);
  const [freeDeliveryPrice, setFDP] = useState(2500);
  const [defaultDeliveryPrice, setDefaultDeliveryPrice] = useState(250);
  const [deliveryZone, setDeliveryZone] = useState(0);

  const [selfDeliveryPoint, setSelfDeliveryPoint] = useState(0);

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState("");
  const [address, setAddress] = useState("");
  const [comment, setComment] = useState("");
  const [commentFl, setCommentFl] = useState("");
  const [commentCurr, setCommentCurr] = useState("");

  const [distance, setDistance] = useState(null);
  const [paymentType, setPaymentType] = useState(1);
  const [movingType, setMovingType] = useState(0);
  const [deliveryType, setDeliveryType] = useState(0);

  const [warnPhone, setWarnPhone] = useState(false);
  const [warnName, setWarnName] = useState(false);
  const [warnPT, setWarnPT] = useState(false);
  const [warnT, setWarnT] = useState(false);
  const [warnAddress, setWarnAddress] = useState(false);

  const [deliverySurprise, setDS] = useState(false);
  const [call, setCall] = useState(false);
  const [callMe, setCallMe] = useState(false);

  const [emptyAddr, setEmptyAddr] = useState(false);
  const [userIsReceiver, setUserIsReceiver] = useState(true);

  const [loading, setLoading] = useState(false);
  const [showNotify, setNotify] = useState(false);

  const [basketFix, setBasketFix] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    //console.log(window.pageYOffset);
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    if (scrollPosition > 220) {
      setBasketFix(true);
    } else {
      setBasketFix(false);
    }
  }, [scrollPosition]);


  useEffect(() => {
    props.getStoredUser();
    props.getStoredUserBasket(false);
    props.getSettingList().then((setting) => {
      if (setting) {
        if (setting.length > 0) {
          setSettings(setting);
        }
      }
    });
    props.getStorageListByCat(5).then((val) => {
      setGifts(convertToList(val).reverse());
    });
  }, []);



  useEffect(() => {
    if (props.login.user) {
      setUser(props.login.user);
      setName(props.login.user.name);
      setPhone(props.login.user.phone);
      setDiscount(props.login.user.discount);
    }
  }, [props.login.user]);



  useEffect(() => {
    setBasket(props.getStoredBasket());
  }, [props.login.basketList]);

  useEffect(() => {
    if (settings.length > 0) {
      setFDP(props.getSettingValue(settings, "sum_busket_free_delivery"));
      setDP(props.getSettingValue(settings, "min_sum_delivery"));
      setDefaultDeliveryPrice(
        props.getSettingValue(settings, "min_sum_delivery")
      );
    }
  }, [settings]);

  useEffect(() => {
    let minPrice =
      getTotalPrice(basketList, deliveryPrice, discount, true) >=
        freeDeliveryPrice
        ? 0
        : defaultDeliveryPrice;
    if (movingType == 1) {
      setDP(0);
    } else {
      if (parseInt(deliveryZone) != null) {
        if (parseInt(deliveryZone) == 0) {
          //если в городе и цена меньше 2500 то цена доставки + тариф, если цена больше 2500 то бесплатно
          setDP(minPrice > 0 ? minPrice : 0);
        } else {
          setDP(minPrice + props.getSettingDelivery(settings, deliveryZone));
        }
      } else {
        setDP(2500);
      }
    }
  }, [
    deliveryZone,
    movingType,
    movingType,
    address,
    distance,
    settings,
    basketList,
  ]);

  function checkForm() {

    setWarnName( name.length == 0);
    setWarnPhone( phone.length <= 7);
    setWarnPT(  paymentType == null);
    if(   (time != "" && address.length > 0) ){
      setWarnT( time == "");
      setWarnAddress( address.length == 0);
    }
     
    return (
      name.length > 0 &&
      phone.length > 7 &&
      ((time != "" && address.length > 0)) &&
      paymentType != null
    );
  }

  function pay() {
    setLoading(true);
    let _address = address;
    let type = OrderConstant.ORDER_SELF_DELIVERY[selfDeliveryPoint];

    props
      .addOrder(
        {
          address_a: _address,
          date: formatDate(date),
          time: time
            .replace(" ", "")
            .split("-")
            .map((item) => {
              return `${Math.round(item.split(":")[0])}:${item.split(":")[1]}`;
            })
            .join("-"),
          moving_types: movingType,
          payment_type: paymentType,
          order_type: type,
          price: getTotalPrice(basketList, deliveryPrice, discount, false),
          price_discount: discount,
          price_delivery: deliveryPrice ? deliveryPrice : 0,
          user_phone: phone,
          user_name: name,
          postcard: "",
          text: comment,
          comment: "",
          user_call: call ? 1 : 0,
        },
        basketList,
        user
      )
      .then((res) => {
        if (res) {
          props.clearUserBasket();
          if (paymentType == 0) {
            setLoading(false);
            navigate(`/pay/${res.id}`);
          } else {
            setTimeout(() => {
              setLoading(false);
              navigate("/order/success");
            }, 500);
          }
        }
      });
  }

  let basket = basketList.map((item, index) => {
    return (
      <UiBasketCard
        key={index}
        image={item.image}
        title={`${item.title}${item.customTitle ? " | " + item.customTitle : ""
          }`}
        text={item.text}
        price={item.price}
        totalPrice={getPrice(item)}
        num={item.count}
        onClick={() => props.changeUserBasket(item.id, 0)}
        onPlus={() => props.changeUserBasket(item.id, item.count + 1)}
        onMinus={() => props.changeUserBasket(item.id, item.count - 1)}
      />
    );
  });

  var giftsList = gifts.map((item, index) => {
    if (index < 5)
      return (
        <UiGiftCard
          key={index}
          item={item}
          selected={props.checkInUserBasket(item.id)}
          onClick={() => {
            props.addUserBasket(item, true);
          }}
        />
      );
  });

  return (
    <PageContainer
      page="order"
      pageTitle="Доставка цветов Южно-Сахалинск"
      alertOpen={true}
      alertTitle="Заголовок"
      alertText="Текст сообщения"
    >
      <UiPageTitle
        linksList={[{ href: "/order", label: "Оформление заказа" }]}
      />
      <Container>
        <div
          className={"order-wrap" + (basket.length === 0 ? " empty-order" : "")}
        >
          {basket.length > 0 ? (
            <div className="order-info">
              <h3>Оформить заказ</h3>
              {user ? (
                <div className="order-info-input">
                  <div className="order-info-input-wrap">
                    <UiTextInput
                      label="Ваше имя"
                      value={name}
                      onChange={(val) => {
                        setName(val); 
                        setWarnName(val.length == 0); 
                      }}
                      warnText={warnName && "Введите ваше имя"}
                    />

                    <div className="order-delivery-type">
                      <button
                        className={
                          "order-delivery-type-btn" +
                          (movingType === 0 ? " active" : "")
                        }
                        onClick={() => setMovingType(0)}
                      >
                        Доставка
                      </button>
                      <button
                        className={
                          "order-delivery-type-btn" +
                          (movingType === 1 ? " active" : "")
                        }
                        onClick={() => setMovingType(1)}
                      >
                        Самовывоз
                      </button>
                    </div>

                    {movingType === 0 ? (
                      <div className="order-delivery-select">
                        <h3>Куда и когда доставить букет?</h3>
                        <UiCheck
                          label="Узнать адрес у получателя"
                          value={emptyAddr}
                          onChange={() => {
                            setEmptyAddr(!emptyAddr);
                          }}
                        />
                        <div className="order-delivery-row">
                          <UiTextInput
                            id={"suggest"}
                            selectText
                            placeholder="Улица"
                            onChange={(v) => { 
                              setAddress(v);
                              setWarnAddress( v.length == 0)
                            }}
                            warnText={warnAddress && "Введите Улицу"}
                          />
                        </div>
                        <div className="order-delivery-row2">
                          <UiTextInput placeholder="Квартира" />
                          <UiTextInput placeholder="Подъезд" />
                          <UiTextInput placeholder="Код" />
                          <UiTextInput placeholder="Этаж" />
                        </div>
                        <div className="order-delivery-row3">
                          <UiSelectDate
                            label="Дата доставки"
                            value={date}
                            onChange={(e) => {
                              setDate(e);
                            }}

                          />
                        </div>

                        <div className="order-delivery-row3">
                          <UiSelectOption
                            label="Время доставки"
                            optionList={toArrayList(
                              OrderConstant.ORDER_TIME_TYPE
                            )}
                            onChange={(e) => {
                              setTime(OrderConstant.ORDER_TIME_TYPE[e]);
                              setWarnT(OrderConstant.ORDER_TIME_TYPE[e] ==  null);
                            }}
                            warnText={warnT && "Введите Время доставки"}
                          />
                        </div>

                        <h3>Кто получит букет?</h3>

                        <UiCheck
                          label="Я получатель"
                          value={userIsReceiver}
                          onChange={() => {
                            setUserIsReceiver(!userIsReceiver);
                          }}
                        />

                        {!userIsReceiver && (
                          <UiTextInput
                            label="Имя получателя"
                            value={name}
                            onChange={(val) => {
                              setName(val);
                            }}
                          />
                        )}
                        {!userIsReceiver && (
                          <UiTextInputMasked
                            label="Номер получателя"
                            value={phone}
                            onChange={(val) => {
                              setPhone(val);
                              setWarnPhone(val.length <= 7);
                             
                            }}
                            warnText={warnPhone && "Введите Номер получателя"}
                          />
                        )}

                        <UiCheck
                          label="Позвонить получателю перед доставкой"
                          value={call}
                          onChange={() => {
                            setCall(!call);
                          }}
                        />

                        <UiCheck
                          label="Анонимно"
                          value={deliverySurprise}
                          onChange={() => {
                            setDS(!deliverySurprise);
                          }}
                        />

                        <div className="order-info-input-wrap">
                          <div id="ymap"></div>
                          <UiTextArea
                            label="Текст записки или комментарий"
                            value={comment}
                            onChange={(val) => {
                              setComment(val);
                            }}
                          />
                        </div>
                        <UiMap
                          address={address}
                          onChangeAddress={(line) => {
                            setAddress(line);
                            setWarnAddress(line.length == 0);
 
                          }}
                          onChange={(obj) => {
                            setDeliveryZone(obj);
                          }}
                        />
                      </div>
                    ) : (
                      <div className="order-delivery-row3">
                        <UiCheckList
                          colored={true}
                          checkList={OrderConstant.ORDER_SELF_DELIVERY_NAMES}
                          label="Адрес самовывоза:"
                          onChange={(val) => {
                            setSelfDeliveryPoint(val);
                          }}
                          selected={selfDeliveryPoint}
                        />
                      </div>
                    )}

                    {movingType === 1 && (
                      <div className="order-delivery-select">
                        <div className="order-delivery-row3">
                          <UiSelectDate
                            label="Дата самовывоза"
                            value={date}
                            onChange={(e) => {
                              setDate(e);
                            }}
                          />
                        </div>
                        <div className="order-delivery-row3">
                          <UiSelectOption
                            label="Время самовывоза"
                            optionList={toArrayList(
                              OrderConstant.ORDER_TIME_TYPE
                            )}
                            onChange={(e) => {
                              setTime(OrderConstant.ORDER_TIME_TYPE[e]);
                            }}
                          />
                        </div>
                      </div>
                    )}

                    <span>
                      Наш менеджер позвонит вам для подтверждения заказа
                    </span>

                    <UiCheck
                      label="Согласовать без звонка (не звонить мне)"
                      value={callMe}
                      onChange={() => {
                        setCallMe(!callMe);
                      }}
                    />

                    {/*
                    <UiCheck
                      label="Подтвердить заказ без звонка"
                      value={aeroWO}
                      onChange={() => {
                        setAeroWO(!aeroWO);
                        setAero(false);
                      }}
                    />
                    */}

                    <UiBtnColor
                      color="opal"
                      onClick={() => {
                        if (checkForm()) pay();
                        else setNotify(true);
                        setTimeout(() => setNotify(false), 1000);
                      }}
                      text="Оформить заказ"
                    />
                  </div>
                </div>
              ) : (
                <div className="order-info-input">
                  <UiBtnColor
                    color="pink"
                    onClick={() => navigate(`/login`)}
                    small
                    text="Войти в личный кабинет"
                  />
                </div>
              )}
            </div>
          ) : null}
          <div className="basket-list-wrap">
            <div className={"basket-list" + (basketFix ? " fixed" : "")}>
              <h3>Моя корзина</h3>
              {basket.length > 0 ? (
                basket
              ) : (
                <div className="basket-list-empty">
                  <SvgBag className="basket-list-empty-icon" />
                  <h4>Ваша корзина пуста</h4>
                  <UiBtnColor
                    color="blue"
                    onClick={() => navigate("/")}
                    small
                    text="В каталог"
                  />
                </div>
              )}
              <p>При заказе от {freeDeliveryPrice} рублей скидка на доставку</p>

              {basket.length > 0 ? (
                <div className="basket-list-coast">
                  <p>Стоимость доставки</p>
                  <p>
                    <strong>
                      {deliveryPrice > 0 ? deliveryPrice : "бесплатно"}
                    </strong>
                  </p>
                </div>
              ) : null}
              {user ? (
                <div className="basket-list-coast">
                  <p>Вам будет начислено</p>
                  <p>
                    <strong>50 бонусов</strong>
                  </p>
                </div>
              ) : null}
              {basket.length > 0 && (
                <div className="basket-list-coast">
                  <p>
                    <b>Общая стоимость заказа</b>
                  </p>
                  <p>
                    <b>
                      {getTotalPrice(
                        basketList,
                        deliveryPrice,
                        discount,
                        false
                      )}{" "}
                      ₽
                    </b>
                  </p>
                </div>
              )}
              <div className="product-add">
                <h3>Не забудьте также добавить к заказу</h3>
                <div className="product-add-gifts">{giftsList}</div>
              </div>
            </div>
          </div>
        </div>

        <UiLoader modalOpen={loading} />
        <UiNotify title="Заполните все поля" show={showNotify} />
      </Container>
    </PageContainer>
  );
}

export default connect(
  ({ storage, login, setting, order }) => ({ storage, login, setting, order }),
  {
    getStoredUser: getStoredUserAction,
    getStorageListByCat: getStorageListByCatAction,
    getStorageItem: getStorageItemAction,

    checkInUserBasket: checkInUserBasketAction,
    addUserBasket: addUserBasketAction,
    getStoredUserBasket: getStoredUserBasketAction,
    getStoredBasket: getStoredBasketAction,
    clearUserBasket: clearUserBasketAction,
    changeUserBasket: changeUserBasketAction,
    getSettingList: getSettingListAction,
    getSettingValue: getSettingValueAction,
    getSettingDelivery: getSettingDeliveryAction,

    addOrder: addOrderAction,
  }
)(OrderPage);
