import React, { useState, useEffect } from "react";

import { useNavigate, useParams, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import {
  getCompositionByStorage as getCompositionByStorageAction,
  getStorageListByCat as getStorageListByCatAction,
  getStorageItem as getStorageItemAction,
} from "../../redux/modules/storage";
import {
  checkInUserBasket as checkInUserBasketAction,
  addUserBasket as addUserBasketAction,
} from "../../redux/modules/login";
import Skeleton from "react-loading-skeleton";

import demo1 from "../../assets/demo/19.jpeg";
import demo2 from "../../assets/demo/20.jpeg";
import demo3 from "../../assets/demo/21.jpeg";

import { ReactComponent as SvgClose } from "../../assets/alert/close.svg";

import UiBarNum from "../../components/bars/UiBarNum";
import UiBtnColor from "../../components/buttons/UiBtnColor";
import UiCheck from "../../components/forms/UiCheck";
import UiCheckList from "../../components/forms/UiCheckList";
import UiGiftCard from "../../components/cards/UiGiftCard";
import UiModal from "./UiModal";
import showNotify from "../../components/notify/UiNotify";

import Env from "../../services/Env";

import "react-loading-skeleton/dist/skeleton.css";
import "./UiModal.scss";
import "./UiModalProduct.scss";

import { convertImages, unCheckInArray } from "../../utilities/array";
import { convertToList } from "../../utilities/storage";
import { getProductPrice } from "../../utilities/order";
import UiAllGiftsCard from "../cards/UiAllGiftsCard";
import UiIcon from "../icon/UiIcon";

const UiModalProduct = (props) => {
  let { title } = useParams();
  const location = useLocation();

  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [item, setItem] = useState(null);
  const [subCat, setSubCat] = useState(null);
  const [image, setImage] = useState(0);
  const [type, setType] = useState(null);
  const [selectedNum, setSelectedNum] = useState(1);

  const demoImages = [demo1, demo2, demo3];

  const [sizes, setSizes] = useState(["11шт", "21шт", "31шт", "51шт", "71шт"]);
  const [productTypes, setProductsTypes] = useState([]);

  const images = [];
  const [gifts, setGifts] = useState([]);
  const [flowers, setFlowers] = useState([]);
  const [additional, setAdditional] = useState([]);
  const [addBasket, setAddBasket] = useState([]);

  useEffect(() => {
    if (title) {
      setLoader(true);
      props.getStorageItem(title).then((val) => {
        if (val) {
          setItem(val);
          setType(null);
          /*
          props.getSubCategory(val.sub_category).then((res) => {
            if (res.length > 0) setSubCat(res[0]);
          });
          */
          props.getCompositionByStorage(val.id).then((res) => {
            if (res.response) {
              if (res.response.length > 0) {
                let arr = [];
                res.response.map((item, index) => {
                  arr.push({
                    option: item.custom_title,
                    price: item.custom_price,
                    storage_id: val.id,
                    id: item.id,
                    index: index + 1,
                  });
                });
                setProductsTypes(arr);
              }
            }
          });
        }
      });
      props.getStorageListByCat(5).then((val) => {
        setGifts(convertToList(val));
      });
      props.getStorageListByCat(4).then((val) => {
        setFlowers(convertToList(val));
      });
      props.getStorageListByCat(7).then((val) => {
        let arr = val
          .filter((item) => {
            return item.price > 0;
          })
          .map((item) => {
            return {
              option: item.title,
              optionInfo: `+ ${item.price} ₽`,
              item: item,
            };
          })
          .reverse();
        setAdditional(arr);
      });
    }
  }, [location]);

  useEffect(() => {
    if (item) {
      setLoader(false);
    }
  }, [item]);

  var imageButtons = (item ? convertImages(item.imgs) : []).map(
    (item, index) => {
      return (
        <button
          key={index}
          className={
            "product-slider-control-button" + (index == image ? " active" : "")
          }
          onClick={() => {
            setImage(index);
            setType(index);
          }}
          style={{ backgroundImage: "url(" + Env.PUBLIC_URL + item + ")" }}
        ></button>
      );
    }
  );

  var sizeList = productTypes.map((sizeItem, index) => {
    return (
      <button
        key={index}
        className={
          "product-size-item" + (item.custom == sizeItem.id ? " active" : "")
        }
        onClick={() =>
          setItem({
            ...item,
            custom: sizeItem.id,
            price: sizeItem.price,
            customTitle: sizeItem.option,
          })
        }
      >
        <div
          className="product-size-item-cover"
          style={{ backgroundImage: "url(" + demo2 + ")" }}
        ></div>
        <p>{sizeItem.option}</p>
      </button>
    );
  });

  var tagsList = (item ? item.tags : "")?.split(",").map((item, index) => {
    return (
      <div key={index} className="product-slider-image-status-item">
        <span>{item}</span>
      </div>
    );
  });

  return (
    <UiModal scroll show={props.modalOpen}>
      <div className="modal-product">
        <button className="modal-close-btn" onClick={props.modalClose}>
          <SvgClose className="modal-close-btn-icon" />
        </button>
        <div className="product-info">
          {/**
           * image slider
           */}
          <div className="product-slider">
            <div className="product-slider-image-wrap">
              {loader ? (
                <Skeleton className="product-slider-image" />
              ) : item ? (
                <div
                  className="product-slider-image"
                  style={{
                    backgroundImage: `url(${Env.PUBLIC_URL}${convertImages(item.imgs)[image]
                      })`,
                  }}
                ></div>
              ) : null}
              <div className="product-slider-control">
                {loader ? (
                  <Skeleton className="product-slider-control-button" />
                ) : (
                  imageButtons.length > 1 && imageButtons
                )}
              </div>
            </div>
          </div>
          {/**
           * product about info
           */}
          <div className="product-about">
            {!loader && (
              <div className="product-slider-image-status">
                {item?.tags && tagsList}
              </div>
            )}
            <h3>
              {loader ? <Skeleton width={200} /> : item ? item.title : null}
            </h3>
            <p>Артикул: {item?.id}</p>
            <div className="product-about-price">
              <p>
                {loader ? <Skeleton width={100} /> : item && `${item.price} ₽`}
              </p>
            </div>{" "}
            <div className="product-description">
              <p>
                {loader ? (
                  <Skeleton count={2} width={"100%"} />
                ) : item ? (
                  item.text
                ) : null}
              </p>
            </div>
            {sizeList.length > 0 && (
              <div className="product-size">
                <h3>Размер букета</h3>
                <p>Эти цветы можно приобрести в другом количестве:</p>
                <div className="product-size-list">{sizeList}</div>
              </div>
            )}
            {loader ? (
              <Skeleton count={1} width={"100%"} />
            ) : (
              <div className="product-all-gifts">
                <h3>Заказав этот букет, вы получите в подарок</h3>
                <UiAllGiftsCard />
              </div>
            )}
            <div className="product-about-sale">
              <UiIcon fill="pink" icon="gift" size={16} />
              <p>Вы получите бонус за этот букет:</p>
              <p>
                <strong>50&nbsp;₽</strong>
              </p>
            </div>
            {/*
            <div className="product-type">
              <h3>Дополнение к букету</h3>
              <UiCheckList
                border
                colored={true}
                checkList={additional}
                label="К этому букету идеально подойдет:"
                onChange={(val) => {
                  //setImage(val);
                  setType(type == val ? null : val);
                }}
                selected={type}
              />
              <span>
                * При отключении всех дополнений, цветы будут перевязаны
                стандартной лентой
              </span>
            </div>
              */}
            <div className="product-add">
              {loader ? (
                <Skeleton width={273.36} height={52} />
              ) : props.checkInUserBasket(item ? item.id : null) ? (
                <UiBtnColor
                  color="opal"
                  text={"Заказать"}
                  onClick={() => {
                    navigate("/order");
                  }}
                />
              ) : (
                <UiBtnColor
                  color="pink"
                  text={"Добавить в корзину"}
                  onClick={() => {
                    item.count = selectedNum;
                    console.log(item);
                    props.addUserBasket(item);
                    if (type) {
                      setAddBasket(
                        unCheckInArray(addBasket, additional[type].item)
                      );
                    }
                    showNotify(`Добавлено: ${item.title}`);
                  }}
                />
              )}
            </div>
            {loader ? (
              <Skeleton
                className="product-add-skeleton"
                count={3}
                width={"100%"}
                height={20}
              />
            ) : (
              item?.category != 5 && (
                <div className="product-description">
                  <h4>Наличие и внешний вид</h4>
                  {subCat?.description_availability ? (
                    <p>{subCat?.description_availability}</p>
                  ) : item?.category != 1 ? (
                    <p>
                      Состав букета и упаковка могут быть изменены в зависимости
                      от ассортимента на текущий момент. При этом мы гарантируем
                      сохранить цветовую гамму и стиль букета.
                    </p>
                  ) : (
                    <p>
                      Состав букета и упаковка могут быть изменены в зависимости
                      от ассортимента на текущий момент. При этом мы гарантируем
                      сохранить цветовую гамму и стиль букета.
                      <br /><br />
                      Данный букет уже собран и готов к доставке. Если вы
                      желаете собрать подобный букет на свой бюджет, обратитесь
                      к нашим менеджерам по телефону.
                    </p>
                  )}

                  {subCat?.description_care && <h4>Как ухаживать</h4>}
                  {subCat?.description_care && (
                    <p>{subCat?.description_care}</p>
                  )}
                  {subCat?.description_whom && <h4>Кому можно дарить</h4>}
                  {subCat?.description_whom && (
                    <p>{subCat?.description_whom}</p>
                  )}
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </UiModal>
  );
};
export default connect(
  ({ storage, login, category }) => ({ storage, login, category }),
  {
    getStorageListByCat: getStorageListByCatAction,
    getStorageItem: getStorageItemAction,
    getCompositionByStorage: getCompositionByStorageAction,
    checkInUserBasket: checkInUserBasketAction,
    addUserBasket: addUserBasketAction,
  }
)(UiModalProduct);
