import React from "react";

import { ReactComponent as SvgLogo } from "../../assets/logo/circle.svg";

import Container from "../../components/container/Container";
import PageContainer from "../../components/container/PageContainer";

import "../../styles/app.scss";

function NotFoundPage() {
  return (
    <PageContainer page="not-found" pageTitle="Доставка цветов Южно-Сахалинск">
      <div className="not-found-wrap">
        <Container>
          <div className="not-found-content">
            <h3>Извините</h3>
            <p>Страница не найдена, возможно она появится позже</p>
            <div className="not-found-image">
              <span>4</span>
              <SvgLogo className="not-found-image-logo" />
              <span>4</span>
            </div>
          </div>
        </Container>
      </div>
    </PageContainer>
  );
}

export default NotFoundPage;
