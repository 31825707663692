import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import demo1 from "../../assets/demo/13.jpeg";
import demo3 from "../../assets/demo/96512873.jpeg";
import demo4 from "../../assets/demo/55.jpeg";
import demo5 from "../../assets/demo/61797063.jpeg";
import demo6 from "../../assets/demo/demo.webp";

import Container from "../../components/container/Container";
import PageContainer from "../../components/container/PageContainer";
import UiCatalog from "../../components/catalog/UiCatalog";
import UiHomeAboutCard from "./components/UiHomeAboutCard";
import UiHomeMobileInfo from "./components/UiHomeMobileInfo";
import UiHomeSpecialSlider from "./components/UiHomeSpecialSlider";
import UiHomeQuizButton from "./components/UiHomeQuizButton";

import UiModalProduct from "../../components/modals/UiModalProduct";

import {
  getStorageListByCat as getStorageListByCatAction,
  getSliderList as getSliderListAction,
} from "../../redux/modules/storage";
import { getMenuCategoryList as getMenuCategoryListAction } from "../../redux/modules/category";

import "../../styles/catalog.scss";

import { convertToList } from "../../utilities/storage";

function HomePage(props) {
  let { title } = useParams();
  const navigate = useNavigate();
  const [promoList, setPromoList] = useState([
    {
      image: demo6,
      title: "",
      text: "",
    }
  ]);
  const [catalogListOnline, setCatalogListOnline] = useState([
    { loader: true },
    { loader: true },
    { loader: true },
    { loader: true },
  ]);

  const [homeCatalog, setHomeCatalog] = useState([
    {
      catalog: [
        { loader: true },
        { loader: true },
        { loader: true },
        { loader: true },
        { loader: true },
        { loader: true },
      ], data: {}
    },
    {
      catalog: [
        { loader: true },
        { loader: true },
        { loader: true },
        { loader: true },
        { loader: true },
        { loader: true },
      ], data: {}
    },
    {
      catalog: [
        { loader: true },
        { loader: true },
        { loader: true },
        { loader: true },
        { loader: true },
        { loader: true },
      ], data: {}
    }
  ]);

  const [modalProductOpen, setModalProductOpen] = useState(false);

  async function _load() {
    const slider = await props.getSliderList(); 
    setPromoList(slider);

    const data = await props.getMenuCategoryList();
    setHomeCatalog(data);

    const list0 = await props.getStorageListByCat(2);
    setCatalogListOnline(convertToList(list0));

  }

  useEffect(() => {
    _load();
  }, []);

  useEffect(() => {
    if (title) setModalProductOpen(true);
  }, [title]);
 
  let homeList = homeCatalog.map((item, index) => {
    if (item.catalog.length > 0) {
      return (
        <UiCatalog
          key={index}
          title={item.data.title}
          subtitle="Наши последние новинки из самых свежих цветов"
          list={convertToList(item.catalog)}
          minimized={false}
          scroll={true}
          white={true}
        />

      )
    }
  });

  return (
    <PageContainer
      page="home"
      pageTitle="Flowery - современная флористика, доставка цветов в Южно-Сахалинске"
      pageDescription=""
      pageKeywords=""
    >
      <section className="home-title-wrap">
        <Container>
          <div className="home-title">
            <div className="home-title-sliders">
              <UiHomeSpecialSlider slideList={promoList} />
              <UiHomeQuizButton />
            </div>
          </div>
        </Container>
      </section>

      {homeList}

      <UiCatalog
        title="Наши эксклюзивные букеты"
        subtitle="Сделай неповторимый и оригинальный подарок"
        filters={true}
        list={catalogListOnline}
        minimized={true}
      />

      <section className="home-gifts-special-wrap">
        <Container>
          <div className="home-gifts-special"></div>
        </Container>
      </section>

      <section className="home-about-wrap">
        <Container>
          <UiHomeAboutCard />
        </Container>
      </section>

      <UiHomeMobileInfo />
      <UiModalProduct
        modalOpen={modalProductOpen}
        modalClose={() => {
          navigate("/");
          setModalProductOpen(false);
        }}
      />
    </PageContainer>
  );
}

export default connect(({ storage }) => ({ storage }), {
  getSliderList: getSliderListAction,
  getStorageListByCat: getStorageListByCatAction,
  getMenuCategoryList: getMenuCategoryListAction,
})(HomePage);
