import React from "react";

import demo2 from "../../../assets/demo/kk.jpeg";

import UiBtnColor from "../../../components/buttons/UiBtnColor";

import "./UiHomeAboutCard.scss";

const UiHomeAboutCard = (props) => {
  return (
    <div className="home-about">
      <div className="home-about-info">
        <h2>
          <span>
            Flowery - это цветочная лавка авторской флористики в Южно-Сахалинске
          </span>
        </h2>

        <p>
          Flowery - это не просто магазин красивых цветов. Здесь вы найдете
          стильные и современные букеты, которые помогут рассказать о ваших
          чувствах и добавить уюта в ваш дом.
        </p>

        <p>
          Мы всегда стремимся предложить вам самые свежие и стильные букеты и
          композиции для приятных и радостных моментов в вашей жизни.{" "}
        </p>
        <p>
          Благодаря прямым контактам с поставщиками цветов в Эквадоре и
          Голландии нам удается поддерживать свежесть цветов на самом высоком
          уровне, и это не просто слова, мы действительно сами закупаем цветы у
          плантаций в Эквадоре и Голландии, и везем их через весь земной шар на
          наш любимый остров.
        </p>
        <h3>
          10 <span>лет работы</span>
        </h3>
        <h3>
          35 010 <span>счастливых клиентов</span>
        </h3>
        <h3>
          27 982 <span>фирменных букетов собрано</span>
        </h3>
        <a
          className="home-about-reviews-btn"
          href="https://yandex.ru/maps/org/flowery/53422456934/?ll=142.732974%2C46.964290&z=16"
        >
          <UiBtnColor color="pink" small text="Отзывы о нас" />
        </a>

      </div>
      <div className="home-about-info">
        <div
          className="home-about-info-image"
          style={{ backgroundImage: "url(" + demo2 + ")" }}
        >
        </div>
      </div>
    </div>
  );
};
export default UiHomeAboutCard;
