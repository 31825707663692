import React, { useState, useEffect } from "react";

import { useNavigate, useParams, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";

import demo1 from "../../assets/demo/19.jpeg";
import demo2 from "../../assets/demo/20.jpeg";
import demo3 from "../../assets/demo/21.jpeg";

import gift1 from "../../assets/demo/13.jpeg";
import gift2 from "../../assets/demo/96512873.jpeg";
import gift3 from "../../assets/demo/55.jpeg";
import gift4 from "../../assets/demo/61797063.jpeg";

import Container from "../../components/container/Container";
import PageContainer from "../../components/container/PageContainer";
import UiBarNum from "../../components/bars/UiBarNum";
import UiBtnColor from "../../components/buttons/UiBtnColor";
import UiCatalog from "../../components/catalog/UiCatalog";
import UiCheck from "../../components/forms/UiCheck";
import UiCheckList from "../../components/forms/UiCheckList";
import UiGiftCard from "../../components/cards/UiGiftCard";
import UiPageTitle from "../../components/header/UiPageTitle";

import {
  getStorageListByCat as getStorageListByCatAction,
  getStorageItem as getStorageItemAction,
} from "../../redux/modules/storage";
import { getSubCategory as getSubCategoryAction } from "../../redux/modules/category";
import {
  checkInUserBasket as checkInUserBasketAction,
  addUserBasket as addUserBasketAction,
} from "../../redux/modules/login";

import Env from "../../services/Env";

import "react-loading-skeleton/dist/skeleton.css";
import "../../styles/product.scss";

import { convertImages, unCheckInArray } from "../../utilities/array";
import { convertToList } from "../../utilities/storage";
import { getProductPrice } from "../../utilities/order";

function ProductPage(props) {
  let { title } = useParams();
  const location = useLocation();

  const navigate = useNavigate();

  const [loader, setLoader] = useState(true);
  const [item, setItem] = useState(null);
  const [subCat, setSubCat] = useState(null);
  const [image, setImage] = useState(0);
  const [type, setType] = useState(0);
  const [selectedNum, setSelectedNum] = useState(1);

  const demoImages = [demo1, demo2, demo3];

  const [sizes, setSizes] = useState(["11шт", "21шт", "31шт", "51шт", "71шт"]);
  const [productTypes, setProductTypes] = useState([
    { option: "Лента в тон цветам", optionInfo: "+ 89 ₽" },
    { option: "Упаковочная бумага", optionInfo: "+ 178 ₽" },
    { option: "Фирменный бокс", optionInfo: "+ 356 ₽" },
  ]);
  const [gifts, setGifts] = useState([]);
  const [flowers, setFlowers] = useState([]);
  const [additional, setAdditional] = useState([]);
  const [addBasket, setAddBasket] = useState([]);

  const demoGiftsList = [
    {
      image: gift1,
      price: "3 000",
      sizeList: [],
      status: ["новинка"],
      title: "Букет Ягодное суфле",
    },
    {
      image: gift2,
      price: "3 000",
      sizeList: ["11шт", "21шт", "31шт", "51шт"],
      status: ["новинка", "хит"],
      title: "Банч Красная шапочка",
    },
    {
      image: gift3,
      price: "3 000",
      sizeList: [],
      status: [],
      title: "Моно букет из цветной гипсофилы",
    },
    {
      image: gift4,
      oldPrice: "5 000",
      price: "3 000",
      sizeList: [],
      status: ["акция"],
      title: "Композиция в шляпной коробке размер S",
    },
  ];

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setLoader(true);
    props.getStorageItem("mattiola").then((val) => {
      if (val) {
        setItem(val);
        props.getSubCategory(val.sub_category).then((res) => {
          if (res.length > 0) setSubCat(res[0]);
        });
      }
    });
    props.getStorageListByCat(5).then((val) => {
      setGifts(convertToList(val));
    });
    props.getStorageListByCat(4).then((val) => {
      setFlowers(convertToList(val));
    });
    props.getStorageListByCat(7).then((val) => {
      setAdditional(convertToList(val).reverse());
    });
  }, [location]);

  useEffect(() => {
    if (item) {
      setLoader(false);
    }
  }, [item]);

  var imageButtons = demoImages.map((item, index) => {
    return (
      <button
        key={index}
        className={
          "product-slider-control-button" + (index == image ? " active" : "")
        }
        onClick={() => {
          setImage(index);
          setType(index);
        }}
        style={{ backgroundImage: "url(" + item + ")" }}
      ></button>
    );
  });

  var sizeList = sizes.map((item, index) => {
    return (
      <button
        key={index}
        className={"product-size-item" + (index === 0 ? " active" : "")}
      >
        <div
          className="product-size-item-cover"
          style={{ backgroundImage: "url(" + demo2 + ")" }}
        ></div>
        <p>{item}</p>
      </button>
    );
  });

  var giftsList = gifts.map((item, index) => {
    if (index < 5)
      return (
        <UiGiftCard
          key={index}
          item={item}
          selected={props.checkInUserBasket(item.id)}
          onClick={() => {
            navigate(`/product/${item.title_url}`);
          }}
        />
      );
  });

  var flowersList = flowers.map((item, index) => {
    if (index < 5)
      return (
        <UiGiftCard
          key={index}
          item={item}
          selected={props.checkInUserBasket(item.id)}
          onClick={() => {
            navigate(`/product/${item.title_url}`);
          }}
        />
      );
  });

  var addList = additional.map((item, index) => {
    if (item.price > 0) {
      return (
        <UiCheck
          key={index}
          label={item.title}
          labelMark={`+ ${item.price} ₽`}
          onChange={() => {
            setAddBasket(unCheckInArray(addBasket, item));
          }}
        />
      );
    }
  });

  var tagsList = (item ? item.tags : "")?.split(",").map((item, index) => {
    return (
      <div key={index} className="product-slider-image-status-item">
        <span>{item}</span>
      </div>
    );
  });

  return (
    <PageContainer
      page="product"
      pageTitle="Доставка цветов Южно-Сахалинск"
      pageDescription={item ? item.title : ""}
      pageKeywords=""
    >
      <UiPageTitle
        linksList={[
          { href: "/catalog", label: "Цветы" },
          { href: "/catalog/monobuketi", label: "Монобукеты" },
          { href: "/catalog/monobuketi/mattiola", label: "Маттиола" },
        ]}
      />
      <Container>
        <div className="product-info">
          {/**
           * image slider
           */}
          <div className="product-slider">
            <div className="product-slider-image-wrap">
              {loader ? (
                <Skeleton className="product-slider-image" />
              ) : item ? (
                <div
                  className="product-slider-image"
                  style={{
                    backgroundImage: "url(" + demoImages[image] + ")",
                  }}
                >
                  <div className="product-slider-image-status">
                    {/*item.tags && tagsList*/}
                    <div className="product-slider-image-status-item">
                      <span>новинка</span>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="product-slider-control">
                {loader ? (
                  <Skeleton className="product-slider-control-button" />
                ) : (
                  imageButtons.length > 1 && imageButtons
                )}
              </div>
            </div>
          </div>
          {/**
           * product about info
           */}
          <div className="product-about">
            <h3>
              {loader ? <Skeleton width={200} /> : item ? item.title : null}
            </h3>
            <p>Артикул: 86898741</p>
            <div className="product-about-price">
              <p>
                {loader ? (
                  <Skeleton width={100} />
                ) : (
                  /*item ? getProductPrice(item, selectedNum) : null*/ "6 198" +
                  " ₽"
                )}
              </p>
            </div>{" "}
            {item ? (
              <div className="product-about-sale">
                <p>
                  {loader ? (
                    <Skeleton width={250} />
                  ) : item.sale > 0 ? (
                    `Вы получите бонус за этот букет:`
                  ) : (
                    ""
                  )}
                </p>
                <p>
                  <strong>
                    {loader ? (
                      <Skeleton width={30} />
                    ) : item.sale > 0 ? (
                      `${item.sale} ₽`
                    ) : (
                      ""
                    )}
                  </strong>
                </p>
              </div>
            ) : null}
            <div className="product-size">
              <h3>Размер букета</h3>
              <p>Эти цветы можно приобрести в другом количестве:</p>
              <div className="product-size-list">{sizeList}</div>
            </div>
            <div className="product-add">
              <UiBarNum
                loader={loader}
                num={selectedNum}
                onMinus={() =>
                  selectedNum > 1 ? setSelectedNum(selectedNum - 1) : 1
                }
                onPlus={() => setSelectedNum(selectedNum + 1)}
              />
              {loader ? (
                <Skeleton width={273.36} height={52} />
              ) : props.checkInUserBasket(item ? item.id : null) ? (
                <UiBtnColor
                  color="pink"
                  text={"Заказать"}
                  onClick={() => {
                    navigate("/order");
                  }}
                />
              ) : (
                <UiBtnColor
                  color="pink"
                  text={"Добавить в корзину"}
                  onClick={() => {
                    item.count = selectedNum;
                    props.addUserBasket(item);
                  }}
                />
              )}
            </div>
            <div className="product-description">
              <p>
                {loader ? (
                  <Skeleton count={2} width={"100%"} />
                ) : item ? (
                  item.text
                ) : null}
              </p>
            </div>
            <div className="product-type">
              <h3>Дополнение к букету</h3>
              <UiCheckList
                colored={true}
                checkList={productTypes}
                label="К этому букету идеально подойдет:"
                onChange={(val) => {
                  setImage(val);
                  setType(val);
                }}
                selected={type}
              />
              <span>
                * При отключении всех дополнений, цветы будут перевязаны
                стандартной лентой
              </span>
            </div>
            {loader ? (
              <Skeleton
                className="product-add-skeleton"
                count={3}
                width={"100%"}
                height={20}
              />
            ) : (
              item.category != 5 && (
                <div className="product-description">
                  <h4>Наличие и внешний вид</h4>
                  {subCat?.description_availability ? (
                    <p>{subCat?.description_availability}</p>
                  ) : item.category != 1 ? (
                    <p>
                      Состав букета и упаковка могут быть изменены в зависимости
                      от ассортимента на текущий момент. При этом мы гарантируем
                      сохранить цветовую гамму и стиль букета.
                    </p>
                  ) : (
                    <p>
                      Данный букет уже собран и готов к доставке. Если вы
                      желаете собрать подобный букет на свой бюджет, обратитесь
                      к нашим менеджерам по телефону.
                    </p>
                  )}

                  {subCat?.description_care && <h4>Как ухаживать</h4>}
                  {subCat?.description_care && (
                    <p>{subCat?.description_care}</p>
                  )}
                  {subCat?.description_whom && <h4>Кому можно дарить</h4>}
                  {subCat?.description_whom && (
                    <p>{subCat?.description_whom}</p>
                  )}
                </div>
              )
            )}
          </div>
        </div>
        {/*<div className="product-add-gift">
          <UiCatalog list={demoGiftsList} />
                  </div>*/}
      </Container>
    </PageContainer>
  );
}

export default connect(
  ({ storage, login, category }) => ({ storage, login, category }),
  {
    getStorageListByCat: getStorageListByCatAction,
    getStorageItem: getStorageItemAction,
    getSubCategory: getSubCategoryAction,
    checkInUserBasket: checkInUserBasketAction,
    addUserBasket: addUserBasketAction,
  }
)(ProductPage);
