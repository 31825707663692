import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import "./UiModal.scss";

const UiModal = ({ children, full, light, show, scroll }) => {
  const [checkVerticalScroll, setCheckVerticalScroll] = useState(false);
  const [topScrollConst, setTopScrollConst] = useState(0);
  const [currentLocation, setCurrentLocation] = useState(null);
  const location = useLocation();

  useEffect(() => {
    if (document.getElementById("modal-inner")) {
      let windowHeight = window.innerHeight;
      let modalHeight = document.getElementById("modal-inner").offsetHeight;

      setCheckVerticalScroll(modalHeight + 100 > windowHeight);
    }
  }, []);

  useEffect(() => {
    if (show) {
      _openModal();
    } else {
      _closeModal();
    }
    setCurrentLocation(location.pathname)
  }, [show]);

  useEffect(() => {
    if (location.pathname.includes("login") || location.pathname.includes("order")) {
      _closeModal();
      window.scrollTo(0, 0); 
    }

  }, [location]);

  const _openModal = () => {
    if (document.getElementById("app")) {
      let top = window.pageYOffset || document.getElementById("app").scrollTop;
      setTopScrollConst(top);

      let element = document.getElementById("app");
      element.classList.add("scroll-lock");
      console.log(top);
      document.getElementById("app").style.top = `-${top}px`;

      document.getElementById("header").classList.add("lock");
      document.getElementById("fixedElem").classList.add("lock");
    }
  };

  const _closeModal = () => {
    let element = document.getElementById("app");
    if (element) {
      element.classList.remove("scroll-lock");

      window.scrollTo(0, topScrollConst);

      document.getElementById("header").classList.remove("lock");
      document.getElementById("fixedElem").classList.remove("lock");
    }
  };

  if (show) {
    return (
      <div
        className={
          "modal" +
          (full ? " full" : "") +
          (light ? " light" : "") +
          (show ? " show" : " hide") +
          (scroll ? " scroll" : " center")
        }
      >
        <div className="modal-inner" id="modal-inner">
          {children}
        </div>
      </div>
    );
  }
};

export default UiModal;
