import React, { useState } from "react";

import UiIcon from "../icon/UiIcon";

import "./UiSelectOption.scss";

const UiSelectOption = (props) => {
  const [activeValue, selectValue] = useState("");

  function changeValue(value) {
    let newVal = value;
    selectValue(newVal);
    if (props.onChange) props.onChange(newVal);
  }

  if (props.optionList) {
    var optionList = props.optionList.map((item, index) => {
      return (
        <option key={index} value={item.value}>
          {item.text}
        </option>
      );
    });
  }

  return (
    <div className={"select-option-wrap"}>
      {props.label ? <label>{props.label}</label> : null}
      <select
        className={
          "select-option"
          +
          (props.warnText && props.warnText != "" ? " text-input-warn" : "")
        }
        onChange={(e) => changeValue(e.target.value)}
        value={props.value}
      >
        {optionList}
      </select>
      <UiIcon icon="arrow-down" size={12} />
      
      {props.warnText && props.warnText != "" ? (
        <p className="text-input-warn">* {props.warnText}</p>
      ) : null}

    </div>
  );
};
export default UiSelectOption;
