import React from "react";

import { ReactComponent as SvgRuble } from "../../assets/payment/ruble.svg";
import { ReactComponent as SvgCard } from "../../assets/payment/card.svg";
import { ReactComponent as SvgCashDelivery } from "../../assets/payment/cash-on-delivery.svg";
import { ReactComponent as SvgQiwi } from "../../assets/payment/qiwi.svg";
import { ReactComponent as SvgTinkoff } from "../../assets/payment/tinkoff.svg";
import { ReactComponent as SvgPayPal } from "../../assets/payment/paypal.svg";

import Container from "../../components/container/Container";
import PageContainer from "../../components/container/PageContainer";
import UiPageTitle from "../../components/header/UiPageTitle";

import "./Static.scss";

function DeliveryPage() {
  return (
    <PageContainer page="delivery" pageTitle="Доставка цветов Южно-Сахалинск">
      <Container>
        <div className="delivery-wrap">
          <div className="delivery-info">
            <h2>Доставка и оплата</h2>
            <div className="delivery-info-item">
              <p>
                Бесплатная доставка по городу за исключением удаленных районов
                при заказе <b>от 2500 рублей</b>. Стоимость доставки по городу
                при заказе менее, чем 2500 рублей - 250 рублей.
              </p>
            </div>
            <div className="delivery-info-item">
              <h3>Доставка осуществляется круглосуточно.</h3>
              <p>
                Исключение - заказы, принятые с сайта в период с 19:00 до 9:00
                ежедневно. По этим заказам самая ранняя доставка возможна только
                с 10:00 следующего дня.
              </p>
              <p>
                Действие персональной скидки клиента на доставку не
                распространяется.
              </p>
            </div>

            <div className="delivery-info-item">
              <h3>Доставка по регионам</h3>
              <iframe
                src="https://yandex.ru/map-widget/v1/?um=constructor%3Ac9f0420f56552ce93e14cec5665f9c1971b8a12850163749b6ebe9c5f23920f3&amp;source=constructor"
                width="100%"
                height="665"
                frameborder="0"
              ></iframe>
            </div>

            <div className="delivery-info-zone">
              <div className="delivery-info-zone-item">
                <h3>
                  Зона #1 <span>100&nbsp;₽</span>
                </h3>
                <div>
                  <p>Курьерская доставка в пределах:</p>
                  <ul>
                    <li>Земляничные холмы</li>
                    <li>Сити Молл</li>
                    <li>Зима</li>
                    <li>Дальнее</li>
                    <li>Луговое</li>
                    <li>Октябрьское</li>
                  </ul>
                </div>
              </div>

              <div className="delivery-info-zone-item">
                <h3>
                  Зона #2 <span>200&nbsp;₽</span>
                </h3>
                <div>
                  <p>Курьерская доставка в пределах:</p>
                  <ul>
                    <li>Троицкое</li>
                    <li>Аэропорт</li>
                    <li>Ново-Александровка</li>
                    <li>Ново-Троицкое</li>
                    <li>Хомутово</li>
                  </ul>
                </div>
              </div>

              <div className="delivery-info-zone-item">
                <h3>
                  Зона #3 <span>300&nbsp;₽</span>
                </h3>
                <div>
                  <p>Курьерская доставка в пределах:</p>
                  <ul>
                    <li>Новая деревня</li>
                    <li>Лиственничное</li>
                    <li>Елочки</li>
                  </ul>
                </div>
              </div>

              <div className="delivery-info-zone-item">
                <h3>
                  Зона #4 <span>400&nbsp;₽</span>
                </h3>
                <div>
                  <p>Курьерская доставка в пределах:</p>
                  <ul>
                    <li>Мицулевка</li>
                  </ul>
                </div>
              </div>

              <div className="delivery-info-zone-item">
                <h3>
                  Доставка в соседние города&nbsp;* <span>2500&nbsp;₽</span>
                </h3>
                <div>
                  <p>Курьерская доставка в пределах:</p>
                  <ul>
                    <li>Анива</li>
                    <li>Корсаков</li>
                    <li>Долинск</li>
                  </ul>
                </div>

                <p>
                  <b>*</b> Доставка осуществляется ТОЛЬКО сторонним курьером. В
                  стоимость доставки включены: аквабокс, транспортировачный
                  бокс, услуги по организации доставки, доставка
                </p>
                <h4>ОПЛАТА ЗАКАЗА ПРИ ПОЛУЧЕНИИ НЕВОЗМОЖНА</h4>
              </div>
            </div>
          </div>

          <div className="payment-info">
            <h2>Информация об оплате</h2>
            <div className="payment-info-item">
              <h3>ПРИНИМАЕМ К ОПЛАТЕ:</h3>
              <p>
                Если товар доставляется курьером, то оплата осуществляется
                банковской картой или наличными. При получении товара
                обязательно проверьте комплектацию товара.
              </p>
            </div>
            <div className="payment-info-item">
              <h3>БАНКОВСКОЙ КАРТОЙ:</h3>
              <p>
                Для выбора оплаты товара с помощью банковской карты на
                соответствующей странице необходимо нажать кнопку Оплата заказа
                банковской картой. Оплата происходит через ПАО СБЕРБАНК с
                использованием банковских карт следующих платёжных систем: МИР,
                VISA, MasterCard, JCB.
              </p>
              <p>
                Для оплаты (ввода реквизитов Вашей карты) Вы будете
                перенаправлены на платёжный шлюз ПАО СБЕРБАНК. Соединение с
                платёжным шлюзом и передача информации осуществляется в
                защищённом режиме с использованием протокола шифрования SSL. В
                случае если Ваш банк поддерживает технологию безопасного
                проведения интернет-платежей Verified By Visa, MasterCard
                SecureCode, MIR Accept, J-Secure, для проведения платежа также
                может потребоваться ввод специального пароля.
              </p>
              <p>
                Настоящий сайт поддерживает 256-битное шифрование.
                Конфиденциальность сообщаемой персональной информации
                обеспечивается ПАО СБЕРБАНК. Введённая информация не будет
                предоставлена третьим лицам за исключением случаев,
                предусмотренных законодательством РФ. Проведение платежей по
                банковским картам осуществляется в строгом соответствии с
                требованиями платёжных систем МИР, Visa Int., MasterCard Europe
                Sprl, JCB.
              </p>
            </div>
            <div className="payment-info-item">
              <h3>ВОЗВРАТ И ОБМЕН ТОВАРА:</h3>
              <p>
                В случае, если заказчику был продан товар ненадлежащего
                качества, то получить возврат денежных средств или обмен на
                другой товар по эквивалентной стоимости можно в цветочной лавке
                FLOWERY после обращения с претензией любым удобным способом
                (звонок по телефону, письмо, отправленное по адресу
                manager@dutyfreerose.ru, сообщение в
                WhatsApp/Telegram/Instagram) с предоставлением фотографии,
                подтверждающей факт ненадлежащего качества товара и
                подтверждением факта покупки (чек, номер заказа и т.д.).
              </p>
              <p>Замена производится только в обмен на старый букет.</p>
              <p>
                Замена букетов, купленных в рознице, уехавших в соседние города
                силами покупателя, НЕ ПРОИЗВОДИТСЯ и считается не гарантийным
                случаем.
              </p>
              <p>
                Свежесрезанные цветы – товар скоропортящийся, поэтому все
                вопросы и претензии по качеству принимаются только в течение 48
                часов с момента осуществления покупки или получения заказа.
              </p>
              <p>
                При оплате товара банковской картой возврат денежных средств
                возможен только на карту, с которой была совершена операция,
                после электронной оплаты оформить возврат наличными нельзя.
                Сроки зачисления могут варьировать от 3 до 40 рабочих дней, в
                зависимости от Банка.
              </p>
              <p>
                Цветы являются живым товаром. К отношениям между Покупателем и
                Продавцом применяется право Российской Федерации.
              </p>
              <p>
                В соответствии с Законом Российской Федерации «О защите прав
                потребителей» от 07.02.1992 № 2300-1 (в ред. от 25.10.2007 г.) и
                Постановлением Правительства Российской Федерации от 19.01.1998
                № 55 (в ред. 27.03.2007 г.) срезанные цветы и горшечные растения
                обмену и возврату не подлежат (указаны в Перечне
                непродовольственных товаров надлежащего качества, не подлежащих
                возврату или обмену).
              </p>
              <p>
                Покупатель имеет право отказаться от получения товара в момент
                доставки или в момент выдачи заказа в цветочной лавке при
                самовывозе, если товар оказался ненадлежащего качества (на
                основании п.3 ст. 497 ГК РФ, статья 21 Закона "О защите прав
                потребителей"). Проверяйте, пожалуйста, купленную продукцию
                сразу на кассе или во время доставки в присутствии курьера.
              </p>
              <p>
                В случае, если клиент оформил заказ, требующий предоплаты
                (эксклюзивный цветок, индивидуальный заказ на плантацию, большой
                объём, шары и т.д.), но решил по какой-либо причине отказаться
                от заказа, то расходы, понесенные цветочной лавкой FLOWERY в
                целях осуществления заказа, оплачиваются самим заказчиком.
              </p>
              <p>
                Контакты:
                <br />
                Звонок: <a href="tel:+78003334409">+7(800)333-44-09</a>
                <br />
                WhatsApp:{" "}
                <a href="https://wa.me/79147541334">+7(914)754-13-34</a>
                <br />
                Telegram:{" "}
                <a href="https://t.me/Flowerybot">https://t.me/Flowerybot</a>
                <br />
                Электронная почта:{" "}
                <a href="mailto:manager@dutyfreerose.ru">
                  manager@dutyfreerose.ru
                </a>
              </p>
            </div>
          </div>
        </div>
      </Container>
    </PageContainer>
  );
}

export default DeliveryPage;
