export default {
  STORE_KEY: 'a56z0fzrNpl^2',
  //Dev

  //Prod
  HOST_URL: 'http://flowery.devkot.ru/',
  API_URL: 'http://flowery.devkot.ru/server/public/api/v1',
  PUBLIC_URL: 'http://flowery.devkot.ru/server/public/uploads/',
  //STORAGE_URL: '',
 
  getHeaders: function () {
    return {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    }
  }
}; 