import React, { useState } from "react";

import UiBtnIcon from "../buttons/UiBtnIcon";
import UiIcon from "../icon/UiIcon";

import "./UiAboutFaqCard.scss";

const UiAboutFaqCard = (props) => {
  const [infoActive, setInfoActive] = useState(false);

  const toggleInfoActive = () => {
    let n_val = !infoActive;
    setInfoActive(n_val);
  };

  const text = props.textList.map((item, index) => {
    return <p key={index}>{item}</p>;
  });

  return (
    <div className={"about-faq-card" + (infoActive ? " active" : "")}>
      <h3 onClick={() => toggleInfoActive()}>
        {props.title} <UiIcon fill="pink" icon="arrow-down" size={20} />
      </h3>
      <div className="about-faq-card-info">
        {text}
        {props.social && (
          <div className="contacts-icons">
            <UiBtnIcon
              color="white"
              fill="dark"
              icon="telegram"
              iconSize={28}
              size={44}
              href="https://t.me/Flowerybot"
            />
            <UiBtnIcon
              color="white"
              fill="dark"
              icon="whatsapp"
              iconSize={28}
              size={44}
              href="https://api.whatsapp.com/send/?phone=%2B79147541334&text&type=phone_number&app_absent=0"
            />
            <UiBtnIcon
              color="white"
              fill="dark"
              icon="vk"
              iconSize={28}
              size={44}
              href="https://vk.com/flowery_sakhalin"
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default UiAboutFaqCard;
