import React from "react";

import { ReactComponent as SvgClock } from "../../assets/about/clock.svg";
import { ReactComponent as SvgDelivery } from "../../assets/about/delivery.svg";
import { ReactComponent as SvgFlower } from "../../assets/about/flower.svg";
import { ReactComponent as SvgImage } from "../../assets/about/image.svg";
import { ReactComponent as SvgPhone } from "../../assets/about/phone.svg";
import { ReactComponent as SvgPhoto } from "../../assets/about/photo.svg";
import { ReactComponent as SvgStar } from "../../assets/about/star.svg";
import { ReactComponent as SvgWhatsapp } from "../../assets/about/whatsapp.svg";

import about1 from "../../assets/about/9JX0zr_Q.jpeg";

import UiBtnColor from "../../components/buttons/UiBtnColor";
import UiBtnIcon from "../../components/buttons/UiBtnIcon";
import UiAboutFaqCard from "../../components/cards/UiAboutFaqCard";

import Container from "../../components/container/Container";
import PageContainer from "../../components/container/PageContainer";
import UiIcon from "../../components/icon/UiIcon";

import "./Static.scss";

function AboutPage() {
  return (
    <PageContainer page="about" pageTitle="Доставка цветов Южно-Сахалинск">
      <Container>
        <div className="about-wrap">
          <div className="about-info-wrap">
            <div className="about-info-image">
              <img src={about1} />
            </div>
            <div className="about-info">
              <h2>О нас</h2>
              <h2>
                FLOWERY - ЭТО ЦВЕТОЧНАЯ ЛАВКА АВТОРСКОЙ ФЛОРИСТИКИ В
                ЮЖНО-САХАЛИНСКЕ
              </h2>
              <p>
                Мы всегда стремимся предложить вам самые свежие и стильные
                букеты и композиции для приятных и радостных моментов в вашей
                жизни.
              </p>
              <p>
                Благодаря прямым контактам с поставщиками цветов в Эквадоре и
                Голландии нам удается поддерживать свежесть цветов на самом
                высоком уровне, и это не просто слова, мы действительно сами
                закупаем цветы у плантаций в Эквадоре и Голландии, и везем их
                через весь земной шар на наш любимый остров.
              </p>
              <a
                className="about-info-btn"
                href="https://yandex.ru/maps/org/flowery/53422456934/?ll=142.732974%2C46.964290&z=16"
              >
                <UiBtnColor color="pink" small text="Отзывы о нас" />
              </a>
              <h3>Что мы делаем для Вас?</h3>
              <p>
                Соберем букет и бесплатно приложим открытку с вашими
                пожеланиями, подкормку для цветов, инструкцию по уходу и
                упаковку для удобства транспортировки и сохранности.
              </p>
              <div className="about-faq">
                <UiAboutFaqCard
                  title="Как заказать букет?"
                  textList={[
                    "Мы принимаем заказы каждый день с 06:00 до 01:00. Оформить заказ можно путём:",
                    "- заявки на сайте в интересующем разделе;",
                    "- звонка по номеру +7 (800) 333-44-09;",
                    "- написать нам на What's app +7(914)754-13-34;",
                  ]}
                  social={true}
                />
                <UiAboutFaqCard
                  title="В какие сроки вы доставляете цветы?"
                  textList={[
                    "ДОСТАВКА",
                    "Доставка осуществляется по городу Южно-Сахалинск.",
                    "Доставляем букеты в указанном интервале, за дополнительную стоимость - точно ко времени.",
                    "Удобное время или интервал доставки Вы можете выбрать при оформлении заказа. Мы доставляем букеты от одного часа, зависит от адреса доставки.",
                    "САМОВЫВОЗ",
                    "Забрать заказ Вы можете самостоятельно из наших салонов по адресу:",
                    "- проспект Победы 9б (9:00-20:00)",
                    "- улица Сахалинская 45 (круглосуточно)",
                    "- улица Сахалинская 69 (круглосуточно)",
                    "- улица Пограничная 31А (с 10:00 до 19:00)",
                    "- улица Ленина 219 (круглосуточно)",
                  ]}
                />
                <UiAboutFaqCard
                  title="Как изменить или отменить заказ?"
                  textList={[
                    "Для изменения или отмены заказа обратитесь к нам по телефону +7 (800) 333-44-09",
                    "- написать нам на What's app +7(914)754-13-34",
                  ]}
                />
                <UiAboutFaqCard
                  title="Будет ли заказанный букет соответствовать представленному на
                    сайте?"
                  textList={[
                    "Букеты мы создаём максимально схожие с фотографией. Приведённые примеры букетов являются возможными вариантами составления композиций , так как каждый букет/композиция собирается вручную нашими флористами, возможна небольшая разница между примером и готовым букетом. При этом возможна замена по количеству, ассортименту и составу цветов, в зависимости от сезонности, наличия и других факторов. Замены всегда являются равноценными образцу по качеству и стоимости.",
                  ]}
                />
                <UiAboutFaqCard
                  title="Как оплатить заказ букета?"
                  textList={[
                    "Оплатить заказ можно любым удобным способом:",
                    "- картой любого банка после оформления заказа;",
                    "- наличными или картой в наших магазинах на проспект Победы 9б, на Сахалинской 45, на Сахалинской 69, на Пограничная 31А, на Ленина 219;",
                    "- наличными или картой курьеру (если доставка с 09:00-20:00); ",
                    "- если вы находитесь заграницей, мы принимаем оплату через сервис PayPal",
                    "* Если вы планируете доставку с 20:00-09:00, заказ нужно оплатить 100%",
                  ]}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="about-info-plus">
          <h3>ПОЧЕМУ МЫ - ЛУЧШИЕ?</h3>
          <div className="plus-list">
            <div className="plus-list-item">
              <div className="plus-list-item-image">
                <SvgStar width={22} height={22} />
              </div>
              <p>Удобный сайт для оформления заказа с безопасной оплатой</p>
            </div>
            <div className="plus-list-item">
              <div className="plus-list-item-image">
                <SvgImage width={20} height={20} />
              </div>
              <p>
                Точное соответствие вашего заказа фотографиям на нашем сайте
              </p>
            </div>
            <div className="plus-list-item">
              <div className="plus-list-item-image">
                <SvgPhoto width={21} height={21} />
              </div>
              <p>
                Перед отправкой пришлем фото, после одобрения отправим в
                доставку
              </p>
            </div>
            <div className="plus-list-item">
              <div className="plus-list-item-image">
                <SvgFlower width={20} height={20} />
              </div>
              <p>
                Подпишем от вас открытку, и сохраним анонимность в нужном случае
              </p>
            </div>
            <div className="plus-list-item">
              <div className="plus-list-item-image">
                <SvgDelivery width={24} height={24} />
              </div>
              <p>У нас собственный штат курьеров, осуществляющих доставку</p>
            </div>
            <div className="plus-list-item">
              <div className="plus-list-item-image">
                <SvgClock width={20} height={20} />
              </div>
              <p>
                Доставляем букет в течение часа после оформления, или в точно
                согласованное с вами время
              </p>
            </div>
            <div className="plus-list-item">
              <div className="plus-list-item-image">
                <SvgPhone width={20} height={20} />
              </div>
              <p>
                Если вы не знаете адрес получателя, позвоним и договоримся о
                доставке по удобному адресу
              </p>
            </div>
          </div>
        </div>
      </Container>
    </PageContainer>
  );
}

export default AboutPage;
